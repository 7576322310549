import { css } from 'styled-components';
import { mediaMax } from '../../../../utils/css';

export const AnswersGridDefinition = css`
  .answers {
    display: grid;

    &.text {
      grid-template-columns: 1fr;
      grid-row-gap: 0.5rem;
    }

    &.image {
      grid-template-columns: 1fr 1fr;
    }
  }
  ${mediaMax.sm`
    .question {
      margin-bottom: 1rem;
    }
  `}
`;
