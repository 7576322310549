import styled from 'styled-components';
import { columnSize, mediaMin } from '../../utils/css';
import { DetailPageHeadline } from '../Headline/Headline.jsx';
import { DetailIntroText } from '../Text/Text.jsx';
import { StyledStandardButton } from '../StandardButton/StandardButton.jsx';

export const UserPageLayout = styled.div`
  padding: 1.5rem ${columnSize.c2} 3rem;

  ${mediaMin.lg`
    padding: 1.5rem ${columnSize.c4} 3rem;
  `}

  > ${DetailPageHeadline} {
    margin: 0;
  }

  > ${DetailIntroText} {
    margin: 0.5rem 0 1rem;
  }

  > form {
    > ${StyledStandardButton} {
      margin: 1rem 0;
      width: 100%;
    }
  }
`;
