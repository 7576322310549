import styled from 'styled-components';
import classnames from 'classnames';
import { StyledLinkListReferenceTeaser as LinkListReferenceTeaser } from '../Teaser/LinkListReferenceTeaser.jsx';
import { colors } from '../../../utils/css';

/**
 * @param {{
 *   className?: string,
 *   contents: {
 *     id: React.Key,
 *     modelType: unknown,
 *     shortTitle: string,
 *     navigations: [],
 *     externalLinks: [],
 *   }[],
 * }} props
 */
export const LinkListBlock = ({ className, contents, background }) => {
  const references = contents.filter(isReference);
  const isContentAvailable = references.length > 0;

  return isContentAvailable ? (
    <div className={classnames(className, background)}>
      {references.map(content => (
        <LinkListReferenceTeaser contents={content} key={content.id} />
      ))}
    </div>
  ) : null;
};

export const StyledLinkListBlock = styled(LinkListBlock)`
  &.white {
    background-color: ${colors.LG_WHITE};
  }

  .grey {
    background-color: ${colors.BLUE_GREY_01};
  }
`;

const isReference = content => content.modelType === 'REFERENCE';
