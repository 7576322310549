import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { StyledLessonExerciseHint as LessonExerciseHint } from '../LessonExerciseHint/LessonExerciseHint.jsx';
import { LessonExerciseQuestion } from '../LessonExerciseQuestion/LessonExerciseQuestion.jsx';
import { StyledSolveCheckButton } from '../../../SolveCheckButton/SolveCheckButton.jsx';
import { useInquiryHandling } from '../workflow/useInquiryHandling';
import { ANSWER_STATE } from '../LessonExerciseAnswer/answerState';
import { useAudio } from '../../../../hooks/useAudio';
import { findFeedbackAudio } from '../../../../utils/audioUtils';
import { LessonExerciseHiddenAudio } from '../LessonExerciseHiddenAudio/LessonExerciseHiddenAudio.jsx';
import { ExerciseAnswer } from '../LessonExerciseAnswer/ExerciseAnswer.jsx';
import { useIsMountedRef } from '../../../../hooks/useIsMountedRef';
import { AnswersGridDefinition } from '../LessonExerciseAnswer/styling';
import { baseExerciseStyles } from '../utils/exerciseCustomCSS';

export const MultiSelectionExercise = ({ className, inquiry, exerciseId }) => {
  const {
    id: inquiryId,
    inquiryDescription,
    audios,
    subInquiries,
    mediaType,
    mainContentImage,
    learningMetaInfo: { achievableScore },
  } = inquiry;
  const isSolutionAudioFallbackMode = Boolean(mainContentImage);
  const correctAnswerAudio = findFeedbackAudio(isSolutionAudioFallbackMode, audios);
  const { target: answerAudio } = correctAnswerAudio || {};
  const { mp3Src: correctAnswerAudioUrl } = answerAudio || {};
  const { alternatives } = subInquiries[0];
  const answerMediaType = mediaType.toLowerCase();

  const [answersFrozen, setAnswersFrozen] = useState(false);
  const [answers, setAnswers] = useState(
    alternatives.map(alternative => {
      return {
        ...alternative,
        answerState: ANSWER_STATE.INITIAL,
      };
    }),
  );
  const {
    isSolveButtonDisabled,
    isCheckButtonDisabled,
    handleCorrectAnswer,
    handleWrongAnswer,
    handleSolved,
    isIncorrigible,
  } = useInquiryHandling({
    inquiryId,
    achievableScore,
  });

  const { playWrongDefaultAudioFeedBack, playCorrectDefaultAudioFeedBack, addRef, playAudio } =
    useAudio();
  const correctAnswerAudioRef = useRef();
  const answersRef = useRef(answers);
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    answersRef.current = answers;
  });
  useEffect(
    () => (correctAnswerAudioUrl ? addRef(correctAnswerAudioRef) : undefined),
    [addRef, correctAnswerAudioUrl],
  );

  const selectOrUnselectAnswer = chosenAnswerIndex => {
    const updatedAnswers = answers.map((answer, index) => {
      if (index !== chosenAnswerIndex) {
        return answer;
      }
      const newAnswerState =
        answer.answerState === ANSWER_STATE.SELECTED ? ANSWER_STATE.INITIAL : ANSWER_STATE.SELECTED;
      return {
        ...answer,
        answerState: newAnswerState,
      };
    });
    setAnswers(updatedAnswers);
  };

  const isSolvedCorrectly = checkedAnswers => {
    const unsolvedOrWrongAnswers = checkedAnswers.filter(answer => {
      const { answerState, isCorrect } = answer;
      return (
        answerState === ANSWER_STATE.WRONG || (answerState === ANSWER_STATE.INITIAL && isCorrect)
      );
    });
    return unsolvedOrWrongAnswers.length === 0;
  };

  const updateAnswers = (answersToUpdate, answerStateEvalFn, skipAnswerConditionalFn) => {
    const updatedAnswers = answersToUpdate.map(answer => {
      if (skipAnswerConditionalFn && skipAnswerConditionalFn(answer) === true) {
        return answer;
      }
      return {
        ...answer,
        answerState: answerStateEvalFn(answer),
      };
    });
    setAnswers(updatedAnswers);
    return updatedAnswers;
  };

  const playSuccessAudio = e => {
    if (correctAnswerAudioUrl) {
      playAudio(e, correctAnswerAudioRef);
    } else {
      playCorrectDefaultAudioFeedBack();
    }
  };

  const onHandleSolveButtonClicked = e => {
    handleSolved();
    playSuccessAudio(e);
    updateAnswers(answers, answer =>
      answer.isCorrect ? ANSWER_STATE.SOLVED : ANSWER_STATE.INITIAL,
    );
    setAnswersFrozen(true);
  };

  const onHandleCheckButtonClicked = e => {
    const checkedAnswers = updateAnswers(
      answers,
      answer => (answer.isCorrect ? ANSWER_STATE.CORRECT : ANSWER_STATE.WRONG),
      answer => answer.answerState !== ANSWER_STATE.SELECTED,
    );

    if (isSolvedCorrectly(checkedAnswers)) {
      handleCorrectAnswer();
      playSuccessAudio(e);
      setAnswersFrozen(true);
    } else {
      handleWrongAnswer();
      if (isIncorrigible) setAnswersFrozen(true);
      playWrongDefaultAudioFeedBack();
      setTimeout(() => {
        if (isMountedRef.current) {
          updateAnswers(answersRef.current, answer =>
            answer.answerState === ANSWER_STATE.WRONG && !isIncorrigible
              ? ANSWER_STATE.INITIAL
              : answer.answerState,
          );
        }
      }, 3000);
    }
  };

  return (
    <div
      className={className}
      data-type={`allocation${answerMediaType}`}
      data-exercise-id={exerciseId}
      aria-atomic="true"
    >
      <div>
        <div className="question">
          <LessonExerciseQuestion {...{ inquiry }} />
        </div>
        <div>
          <div className={`answers ${answerMediaType}`} aria-multiselectable="true">
            {answers.map((answer, index) => (
              <ExerciseAnswer
                key={answer.id}
                disabled={answersFrozen}
                answerMediaType={answerMediaType}
                answer={answer}
                index={index}
                onAnswerClicked={selectOrUnselectAnswer}
              />
            ))}
          </div>
        </div>
      </div>
      <LessonExerciseHint description={inquiryDescription} />
      <StyledSolveCheckButton
        {...{
          isSolveButtonDisabled,
          isCheckButtonDisabled,
          handleCheckClicked: onHandleCheckButtonClicked,
          handleSolveClicked: onHandleSolveButtonClicked,
        }}
      />
      {correctAnswerAudioUrl && (
        <LessonExerciseHiddenAudio ref={correctAnswerAudioRef} src={correctAnswerAudioUrl} />
      )}
    </div>
  );
};

export const StyledMultiSelectionExercise = styled(MultiSelectionExercise)`
  ${AnswersGridDefinition}
  ${baseExerciseStyles}
`;
