import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { I18nText } from '../I18n/I18nText.jsx';
import { StyledInput } from '../FormElements/Input/StyledInput.jsx';
import { emailPattern } from '../../utils/validation/validators';
import { StyledReCaptcha as ReCaptcha } from '../ReCaptcha';
import { StyledStandardButton as StandardButton } from '../StandardButton/StandardButton.jsx';
import { requestResetPassword } from '../../services/passwordService';
import { feedbackHelper } from '../../utils/url/feedbackHelpers';
import { useLanguage } from '../../context/LanguageContext';
import { DetailPageHeadline } from '../Headline/Headline.jsx';
import { DetailIntroText } from '../Text/Text.jsx';
import { UserPageLayout } from './UserPageLayout.jsx';

export const PasswordReset = ({ className }) => {
  const [executeCaptcha, updateExecuteCaptcha] = useState();
  const setExecuteCaptcha = useCallback(
    newExecuteCallback => updateExecuteCaptcha(() => newExecuteCallback),
    [updateExecuteCaptcha],
  );
  const { langCode } = useLanguage();
  const history = useHistory();

  const formMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });
  const { handleSubmit } = formMethods;

  const onSubmit = async data => {
    try {
      const captchaToken = await executeCaptcha();
      if (captchaToken === null) {
        return;
      }

      const response = await requestResetPassword({
        userData: data,
        captchaToken,
      });
      if (response.ok) {
        history.push(feedbackHelper.forPasswordResetRequest(langCode));
      } else {
        const responseJson = await response.json();
        history.push(feedbackHelper.getForErrorCode(responseJson, langCode));
      }
    } catch (err) {
      history.push(feedbackHelper.getForErrorCode(err, langCode));
    }
  };

  return (
    <UserPageLayout className={className}>
      <I18nText isA={DetailPageHeadline} translation="profile.edit.password.reset.title" />
      <I18nText isA={DetailIntroText} translation="profile.edit.password.reset.subtitle" />
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledInput
            name="email"
            labelKey="profile.edit.password.reset.email"
            registerOptions={{
              required: true,
              pattern: emailPattern,
            }}
          />
          <StandardButton
            titleKey="profile.edit.password.reset.submit"
            data-testid="resetPasswordButton"
          />
          <ReCaptcha languageCode={langCode} getExecuteAsync={setExecuteCaptcha} />
        </form>
      </FormProvider>
    </UserPageLayout>
  );
};
