import styled from 'styled-components';
import { StyledRichTextWithKnowledge as RichTextWithKnowledge } from '../../../RichText/RichTextWithKnowledge.jsx';

export const LessonExerciseTextInput = ({ inputText, knowledges, className }) =>
  inputText && (
    <div className={className}>
      <RichTextWithKnowledge knowledges={knowledges} content={inputText} />
    </div>
  );

export const StyledLessonExerciseTextInput = styled(LessonExerciseTextInput)`
  margin-bottom: -1rem;
  ${RichTextWithKnowledge} {
    p:last-child {
      margin-bottom: 0;
    }
  }
`;
