import styled from 'styled-components';
import { AudioProvider } from '../../../context/AudioContext.jsx';
import { StyledNavigationRow as NavigationRow } from './NavigationRow/NavigationRow.jsx';
import { StyledStandardButton as StandardButton } from '../../StandardButton/StandardButton.jsx';
import { mediaMin, colors, columnSize } from '../../../utils/css';

export const LessonKnowledgeNavigationContainer = ({
  className,
  children,
  currentItem,
  previousItem,
  nextItem,
  moveTo,
  onNextClicked,
}) => {
  return (
    <div className={className}>
      <div className="knowledge-wrapper">
        <AudioProvider>
          <NavigationRow {...{ currentItem, previousItem, nextItem, moveTo }} />
          {children}
          <StandardButton
            data-testid={nextItem ? 'nextKnowledgeButton' : 'finishLessonButton'}
            titleKey={nextItem ? 'common.next' : 'exercise.toSummary'}
            onClick={onNextClicked}
          />
        </AudioProvider>
      </div>
    </div>
  );
};

export const StyledLessonKnowledgeNavigationContainer = styled(LessonKnowledgeNavigationContainer)`
  background-color: ${colors.LG_WHITE};

  .knowledge-wrapper {
    padding-left: ${columnSize.c1};
    padding-right: ${columnSize.c1};
    display: flex;
    flex-direction: column;

    ${mediaMin.md`
      margin-left: ${columnSize.c1};
      width: ${columnSize.c28};
    `}

    ${mediaMin.xl`
      margin-left: ${columnSize.c2};
      width: ${columnSize.c26};
    `}
  }

  ${StandardButton} {
    margin: 2rem auto 3rem;
  }
`;
