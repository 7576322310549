import gql from 'graphql-tag';
import styled from 'styled-components';
import { memoizeWith } from 'ramda';
import {
  AudioDownloadTeaser,
  audioDownloadTeaserFragment,
} from './ContentDetailTeasers/AudioDownloadTeaser.jsx';
import { DownloadTeaser, downloadTeaserFragment } from './ContentDetailTeasers/DownloadTeaser.jsx';
import { colors, columnSize } from '../../utils/css';
import { linkHasValidTarget, linkIsNotInText } from '../../utils/commons';
import { selectVideoResourcesForDownload, getVideoLinkTitle } from '../../utils/videoUtils';
import { I18nText } from '../I18n/I18nText.jsx';
import { StyledLinkTeaser as LinkTeaser } from '../CoCo/Teaser/LinkTeaser.jsx';
import { getVideoDownloadUrl } from '../../utils/url/url';
import { ContentBlockHeadline } from '../Headline/Headline.jsx';

const acceptedInternalTypes = 'ARTICLE, AUDIO, LESSON, NAVIGATION, VIDEO';

const fragmentName = ({ type }) => `${type}RecommendedArea`;
export const recommendedAreaFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    ({ type }) => type,
    ({ type }) => gql`
      fragment ${fragmentName({ type })} on ${type} {
        ...on AssociationsAspect {
          internalLinks: contentLinks(targetTypes: [${acceptedInternalTypes}]) {
            id
            modelType: targetType
            name
            description
            target {
              ... on UrlAspect {
                namedUrl
              }
              ... on Video {
                downloadable
                videoResourceLinks {
                  url
                  format
                  size
                  techFormat
                }
              }
            }
            ...${audioDownloadTeaserFragment.name}
          }
          externalLinks {
            id
            isInText
            name
            url
          }
          downloads: contentLinks(targetTypes: [DOWNLOAD]) {
            id
            ...${downloadTeaserFragment.name}
          }
        }
      }
      ${downloadTeaserFragment.fragment()}
      ${audioDownloadTeaserFragment.fragment()}
    `,
  ),
};

const teaserForInternalLink = link => {
  // NOTE: audios/videos are not separated in query, to retain the server ordering.
  if (link.modelType === 'AUDIO') {
    return <AudioDownloadTeaser link={link} key={link.id} />;
  }

  if (link.modelType === 'VIDEO') {
    if (link.target.downloadable === false) return null;
    // NOTE: this returns an array, which isn't a problem because react automatically flattens.
    return selectVideoResourcesForDownload(link.target.videoResourceLinks).map(resourceLink => (
      <LinkTeaser
        contents={{
          name: getVideoLinkTitle({ ...resourceLink, name: link.name }),
          url: getVideoDownloadUrl({ videoUrl: resourceLink.url, format: resourceLink.format }),
        }}
        key={resourceLink.url}
      />
    ));
  }

  return <LinkTeaser contents={link} key={link.id} />;
};

const teaserForExternalLink = link => <LinkTeaser contents={link} key={link.id} />;

const teaserForDownloadLink = link => <DownloadTeaser link={link} key={link.id} />;

export const RecommendedArea = ({ className, content = {} }) => {
  const { internalLinks = [], externalLinks = [], downloads = [] } = content;

  const internalTeasers = internalLinks.filter(linkHasValidTarget).map(teaserForInternalLink);
  const externalTeasers = externalLinks.filter(linkIsNotInText).map(teaserForExternalLink);
  const downloadTeasers = downloads.map(teaserForDownloadLink);

  // NOTE: downloads are required to be put at the end, despite being internal.
  const recommendedLinks = [...internalTeasers, ...externalTeasers, ...downloadTeasers].filter(
    teaser => !!teaser,
  );

  if (!recommendedLinks?.length > 0) return null;

  return (
    <div className={className}>
      <I18nText
        isA={ContentBlockHeadline}
        translation="content_detail_block.recommendation.headline"
      />
      <div>{recommendedLinks}</div>
    </div>
  );
};

export const StyledRecommendedArea = styled(RecommendedArea)`
  background-color: ${colors.BLUE_GREY_01};
  padding: 3rem ${columnSize.c2} 3rem;
  > div {
    display: grid;
    grid-template-columns: 1fr;
  }

  ${ContentBlockHeadline} {
    margin: 0 0 1rem;
  }
`;
