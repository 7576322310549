import styled from 'styled-components';
import { StyledRichText as RichText } from '../../../RichText/RichText.jsx';
import { StyledResponsiveDwPicture as ResponsiveDwPicture } from '../../../ResponsiveDwPicture/ResponsiveDwPicture.jsx';
import { StyledVocabularyAudio as VocabularyAudio } from './VocabularyAudio.jsx';
import { colors, columnSize, mediaMax, mediaMin } from '../../../../utils/css';
import { CaptionText } from '../../../Text/Text.jsx';
import { DateTimeInfo } from '../../../Info/Info.jsx';

export const VocabularyRow = ({ data, className }) => {
  const { name, subTitle, audios, text } = data;
  const audio = audios[0];
  const isAudioSource = audio && audio.mp3Src;
  const isValidImage = audio && audio.mainContentImage;

  return (
    <div className={className}>
      <div>
        {isAudioSource ? (
          <VocabularyAudio mp3Src={audio.mp3Src}>
            <strong>{name}</strong>
          </VocabularyAudio>
        ) : (
          <DateTimeInfo as="strong">{name}</DateTimeInfo>
        )}
        <br />
        {subTitle && <CaptionText>{subTitle}</CaptionText>}
      </div>
      <div>{isValidImage && <ResponsiveDwPicture image={audio.mainContentImage} alt={name} />}</div>
      <div>
        <RichText content={text} noContainer />
      </div>
    </div>
  );
};

export const StyledVocabularyRow = styled(VocabularyRow)`
  ${mediaMin.md`
    display: grid;
    grid-template-columns: 9fr 8fr 9fr;
  `}

  ${mediaMin.xl`
    display: grid;
    grid-template-columns: 9fr 6fr 9fr;
  `}

  > div {
    padding: 1rem ${columnSize.c1};

    &:nth-child(2) {
      ${mediaMax.sm`
        padding-top: 0;
        padding-bottom: 0;
      `}
    }

    ${mediaMin.md`
      border-top: 1px solid ${colors.DW_GREY_07};
    `}

    p {
      margin: 0;
    }

    &:first-child {
      border-top: 1px solid ${colors.DW_GREY_07};

      /* @noflip */
      text-align: left;
      /* @noflip */
      direction: ltr;
    }
  }

  &:first-child {
    padding-top: 2rem;
  }
`;
