import { useMemo } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { mediaMax, columnSize, fontSize, mediaMin, colors } from '../../../utils/css';
import { StyledLessonTeaser as LessonTeaser } from '../Teaser/LessonTeaser.jsx';
import { StyledArticleTeaser as ArticleTeaser } from '../Teaser/ArticleTeaser.jsx';
import { StyledReferenceTeaser as ReferenceTeaser } from '../Teaser/ReferenceTeaser.jsx';
import { StyledCourseTeaser as CourseTeaser } from '../Teaser/CourseTeaser.jsx';
import { TeaserHeadline } from '../../Headline/Headline.jsx';

export const TEASER_TYPES = Object.freeze({
  LESSON: LessonTeaser,
  ARTICLE: ArticleTeaser,
  REFERENCE: ReferenceTeaser,
  COURSE: CourseTeaser,
});

export const FormatBlock = ({ className, contents, background }) => {
  const contentsWithTag = useMemo(
    () =>
      contents.filter(isAllowedTeaser).map(c => ({ Tag: TEASER_TYPES[c.modelType], teaser: c })),
    [contents],
  );
  const odd = contentsWithTag.length % 2 === 1;
  const getLayout = len => {
    if (len === 1 || len % 3 === 0) return null;
    // eslint-disable-next-line no-bitwise
    if ((len & (len - 1)) === 0) return 'power2';
    if ((len + 1) % 3 === 0) return 'first2';
    return 'first4';
  };

  const layout = getLayout(contentsWithTag.length);

  return (
    <div className={classnames(className, layout, { odd }, background)}>
      {contentsWithTag.map(({ teaser, Tag }) => (
        <div key={teaser.id} className="teaser-wrap">
          <Tag className={classnames({ single: contentsWithTag.length === 1 })} contents={teaser} />
        </div>
      ))}
    </div>
  );
};

export const StyledFormatBlock = styled(FormatBlock)`
  display: grid;
  /* @noflip */
  direction: ltr;
  grid-template-columns: 1fr;
  padding: 2rem ${columnSize.c2} 3rem;
  gap: 3rem ${columnSize.c1};

  &.white {
    background-color: ${colors.LG_WHITE};
  }
  &.grey {
    background-color: ${colors.BLUE_GREY_01};
  }

  ${mediaMin.md`
    /* @noflip */
    grid-template-columns: 1fr 1fr;
    & > *:nth-child(odd),
    &.odd > *:nth-child(even) {
      padding: 0 ${columnSize.c_half} 1rem 0;
    }

    /* @noflip */
    & > *:nth-child(even),
    &.odd > *:nth-child(odd) {
      padding: 0 0 1rem ${columnSize.c_half};
    }

    & > *.teaser-wrap:only-child,
    &.odd > *:nth-child(1){
       grid-column: span 2;
       padding: 0;
    }
  `}
  ${mediaMin.lg`
    grid-template-columns: repeat(6, 1fr);
    & > * {
      grid-column: span 2;
      padding: 0;
    }
    &.power2 > *,
    &.first2 > *:nth-child(-n+2),
    &.first4 > *:nth-child(-n+4) {
       grid-column: span 3;
       padding: 0 0 1rem;
    }
    & > *.teaser-wrap:only-child {
       grid-column: span 6;
       padding: 0;
    }
 
    &.first2 > *:nth-child(n+3),
    &.first4 > *:nth-child(n+5),
    &.odd > *:nth-child(odd),
    &.odd > *:nth-child(even),
    & > *:nth-child(odd),
    & > *:nth-child(even) {
       padding: 0;
    }

    /* @noflip */
    &.first2 > *:nth-child(1),
    &.first4 > *:nth-child(1),
    &.first4 > *:nth-child(3),
    &.power2 > *:nth-child(odd) {
       padding: 0 ${columnSize.c_half} 0 0;
    }

    /* @noflip */
    &.first2 > *:nth-child(2),
    &.first4 > *:nth-child(2),
    &.first4 > *:nth-child(4),
    &.power2 > *:nth-child(even) {
      padding: 0 0 0 ${columnSize.c_half};
    }
  `}

  /*
   * NOTE: Teaser font-size depends on how much space the teaser takes.
   *       For 1/2 (except in md) and bigger, the size is plus2.
   */
  ${mediaMax.sm`
    ${TeaserHeadline} {
      ${fontSize.plus2}
    }
  `}

  ${mediaMax.md`
    &.odd > *:first-child ${TeaserHeadline} {
      ${fontSize.plus2}
    }
  `}

  ${mediaMin.lg`
    &.power2,
    &.first2 > *:nth-child(-n+2),
    &.first4 > *:nth-child(-n+4), 
    & > *.teaser-wrap:only-child {
      ${TeaserHeadline}{
        ${fontSize.plus2}
      }
    }
  `}

  > .teaser-wrap > * {
    /** Let teasers fill their container completely */
    height: 100%;
    width: 100%;
  }
`;

/**
 * @template {{ modelType: unknown }} T
 * @param {T} teaser
 * @returns {teaser is T & { modelType: keyof TEASER_TYPES }}
 */
function isAllowedTeaser(teaser) {
  return Object.keys(TEASER_TYPES).includes(teaser.modelType);
}
