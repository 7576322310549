import gql from 'graphql-tag';
import styled from 'styled-components';
import { memoizeWith } from 'ramda';
import { StyledRichTextWithKnowledge as RichTextWithKnowledge } from '../RichText/RichTextWithKnowledge.jsx';
import { StyledContentContainer as ContentContainer } from '../ContentContainer/ContentContainer.jsx';
import { StyledAccordionContainer as AccordionContainer } from '../ContentContainer/AccordionContainer.jsx';
import { useTranslation } from '../../hooks/useTranslation';
import { StyledToggleableArrow } from '../ToggleableArrow/ToggleableArrow.jsx';

const fragmentName = 'LessonManuscript';
export const manuscriptFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on Lesson {
        id
        manuscript
      }
    `,
  ),
};

export const Manuscript = ({ knowledges, manuscript, isStandalone, className }) => {
  const titleTranslation = useTranslation('lesson.menu.manuscript');

  if (isStandalone) {
    return (
      <ContentContainer title="lesson.menu.manuscript" className={className}>
        <RichTextWithKnowledge knowledges={knowledges} content={manuscript} />
      </ContentContainer>
    );
  }

  return (
    <AccordionContainer
      className={className}
      summaryContent={
        <h4>
          {titleTranslation}
          <StyledToggleableArrow aria-hidden />
        </h4>
      }
    >
      <RichTextWithKnowledge knowledges={knowledges} content={manuscript} />
    </AccordionContainer>
  );
};

export const StyledManuscript = styled(Manuscript)`
  &${ContentContainer} {
    margin: 1rem 0;
    ${RichTextWithKnowledge} {
      margin: 2rem 0;
    }
  }

  &${AccordionContainer} {
    margin: 0;
    ${RichTextWithKnowledge} {
      margin: 1rem 0;
    }
  }

  ${RichTextWithKnowledge} {
    /* @noflip */
    text-align: left;
    /* @noflip */
    direction: ltr;
  }
`;
