import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useMemo } from 'react';
import { clearfix, colors, mediaMin, columnSize } from '../../../../utils/css';
import { I18nText } from '../../../I18n/I18nText.jsx';
import { LanguageSelectionButton } from './LanguageSelectionButton/LanguageSelectionButton.jsx';
import { MENU_LANGUAGES } from '../../../../constants/menuLanguageCodes';
import {
  getDashboardUrl,
  getGrammarUrl,
  getHelpUrl,
  getLoginUrl,
  getPlacementDashboardUrl,
  getUserProfileUrl,
  getVocabularyPageUrl,
  getVocabularyTrainerStartPage,
} from '../../../../utils/url/urlFactory';
import { logOutUser } from '../../../../state/user/userDuck';
import { userIsLoggedIn } from '../../../../state/user/userSelectors';
import { useGlobalsContext } from '../../../../context/GlobalsContext';
import { feedbackHelper } from '../../../../utils/url/feedbackHelpers';
import { useNavigationContext } from '../NavigationContext.jsx';
import { useLanguage } from '../../../../context/LanguageContext';
import { useNavigationRoot } from '../../../../context/NavigationRootContext';
import { useI18nContext } from '../../../../context/I18nContext.jsx';
import { toLangCode } from '../../../../utils/mappers/langMapper';
import { keyCodes } from '../../../../constants/keyCodes';
import { NavigationLinkInteraction } from '../../../Interaction/Interaction.jsx';

export const NavMenu = ({ className }) => {
  const { document } = useGlobalsContext();
  const { langCode } = useLanguage();
  const { i18n } = useI18nContext();
  const isLoggedIn = useSelector(userIsLoggedIn);
  const isLoggedInClass = !isLoggedIn ? 'hidden' : undefined;
  const dispatch = useDispatch();
  const history = useHistory();
  const { isNavMenuOpen, isLanguageSubMenuOpen, closeMenu, toggleLanguageSubNavMenu } =
    useNavigationContext();
  const { learningLevelNavigations, allCoursesNavigation } = useNavigationRoot() ?? {};

  const handleLogoutClicked = e => {
    e.preventDefault();
    closeMenu();
    dispatch(logOutUser())
      .then(() => history.push(feedbackHelper.forLogout(langCode)))
      .catch(err => history.push(feedbackHelper.getForErrorCode(err, langCode)));
  };

  const sortedLanguages = useMemo(() => {
    return [...MENU_LANGUAGES].sort((a, b) => {
      const aTranslation = i18n.t(`header.menu.language.${a.translationKey}`);
      const bTranslation = i18n.t(`header.menu.language.${b.translationKey}`);

      return aTranslation.localeCompare(bTranslation, langCode);
    });
  }, [i18n, langCode]);

  const jumpToBurgerButton = evt => {
    if (!evt.shiftKey && evt.key === keyCodes.TAB) {
      evt.preventDefault();
      document.querySelector('[data-testid="openMainMenuButton"]').focus();
    }
  };

  return (
    <div id="nav-user-menu" className={className} aria-expanded={isNavMenuOpen}>
      <StyledNavigationList aria-label="Main navigation submenu">
        <li>
          <I18nText
            isA={NavigationLinkInteraction}
            as={Link}
            translation="header.menu.home"
            to={allCoursesNavigation?.namedUrl ?? getDashboardUrl(langCode)}
            onClick={closeMenu}
          />
        </li>
        <li>
          <I18nText
            isA={NavigationLinkInteraction}
            as={Link}
            translation="header.menu.levelTest"
            to={getPlacementDashboardUrl(langCode)}
            onClick={closeMenu}
          />
        </li>
        {learningLevelNavigations &&
          learningLevelNavigations.map(page => (
            <li key={page.id}>
              <NavigationLinkInteraction
                as={Link}
                to={page.namedUrl}
                className={`learningLevel ${toLangCode(page.language)}`}
                onClick={closeMenu}
              >
                {page.name}
              </NavigationLinkInteraction>
            </li>
          ))}
        <li>
          <I18nText
            isA={NavigationLinkInteraction}
            as={Link}
            translation="header.menu.vocabulary"
            to={getVocabularyPageUrl(langCode)}
            onClick={closeMenu}
          />
        </li>
        <li>
          <I18nText
            isA={NavigationLinkInteraction}
            as={Link}
            translation="header.menu.grammar"
            to={getGrammarUrl(langCode)}
            onClick={closeMenu}
          />
        </li>
        <li id="vocabularyTrainerButton" className={isLoggedInClass}>
          <I18nText
            isA={NavigationLinkInteraction}
            as={Link}
            translation="header.menu.vocabularyTrainer"
            to={getVocabularyTrainerStartPage(langCode)}
            onClick={closeMenu}
          />
        </li>
        <li>
          <I18nText
            isA={NavigationLinkInteraction}
            as={Link}
            translation="metadata.help"
            to={getHelpUrl(langCode)}
            onClick={closeMenu}
          />
        </li>
        <li id="languageMenu">
          <nav id="navbar-lang" data-testid="navbar-lang">
            <I18nText
              isA={NavigationLinkInteraction}
              as="button"
              translation="header.menu.language.title"
              id="languageMenuButton"
              variant="quaternary"
              aria-expanded={isLanguageSubMenuOpen}
              data-toggle="collapse"
              data-parent="languageMenu"
              data-target="#nav-lang-menu"
              onClick={toggleLanguageSubNavMenu}
            />

            <ul id="nav-lang-menu" className={isLanguageSubMenuOpen ? 'open' : undefined}>
              {sortedLanguages.map(language => (
                <LanguageSelectionButton
                  key={language.translationKey}
                  langCode={langCode}
                  language={language.langCode}
                  translationKey={language.translationKey}
                />
              ))}
            </ul>
          </nav>
        </li>
        <li id="profileButton" className={isLoggedInClass}>
          <I18nText
            isA={NavigationLinkInteraction}
            as={Link}
            translation="header.menu.myArea"
            to={getUserProfileUrl(langCode)}
            onClick={closeMenu}
          />
        </li>
        <li
          id="signInButton"
          className={isLoggedIn ? 'hidden' : undefined}
          style={{ borderBottom: 0 }}
        >
          <I18nText
            isA={NavigationLinkInteraction}
            as={Link}
            translation="header.menu.login"
            data-tracking-name="directLogin"
            to={getLoginUrl(langCode)}
            onKeyDown={jumpToBurgerButton}
          />
        </li>
        <li id="signOutButton" className={isLoggedInClass} style={{ borderBottom: 0 }}>
          <I18nText
            isA={NavigationLinkInteraction}
            as="button"
            translation="header.menu.logout"
            variant="quaternary"
            onClick={handleLogoutClicked}
            onKeyDown={jumpToBurgerButton}
          />
        </li>
      </StyledNavigationList>
    </div>
  );
};

export const StyledNavMenu = styled(NavMenu)`
  border-top: 1px solid ${colors.LG_BLUE_4};
  display: block;
  position: relative;
  right: 0;
  transition: all 0.3s linear;
  z-index: 110;
  max-height: 1500px;
  overflow-x: hidden;
  overflow-y: auto;

  /* To make sure safari navigation controls don't cover the bottom of the menu */
  --safari-padding: 0px;
  /* Checking for an arbitrary safari on iOS feature */
  @supports (-webkit-touch-callout: none) {
    --safari-padding: 120px;
  }

  height: calc(100vh - 60px - var(--safari-padding));

  ${mediaMin.md`
    top: -5px;
    height: calc(100vh - 80px - var(--safari-padding))};
  `}

  ${mediaMin.xl`
    position: absolute;
    /*! @noflip */
    right: 0;
    top: 85px;
    width: 400px;
    overflow: unset;
    height: calc(100vh - 200px - var(--safari-padding))};
  `}
`;

export const StyledNavigationList = styled('ul')`
  background-color: ${colors.DW_LIGHT_BLUE_NEW};
  box-shadow: 0 9px 10px ${colors.LG_TRANSPARENT_BLACK_40};
  float: left;
  margin: 0;
  padding: 37.5px 0 19.5px;
  width: 100%;

  ${mediaMin.xl`
    padding-top: 27.5px;
    padding-bottom: 27.5px;
  `}

  ${clearfix}

  > li {
    display: block;
    padding: 0;
    width: 100%;
  }

  > li.hidden {
    display: none;
  }

  a,
  button {
    background-color: ${colors.DW_LIGHT_BLUE_NEW};
    padding: 12.5px ${columnSize.c1};
    text-decoration: none;
    text-align: left;
    cursor: pointer;
    display: inline-block;
    width: 100%;
    border: none;

    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
    }
  }

  #navbar-lang {
    padding: 0;

    ul {
      margin-top: 0;
      padding-left: 0;
    }
  }

  #nav-lang-menu {
    overflow: hidden;
    transition: all 0.35s ease-in-out;
    max-height: 1px;
    visibility: hidden;

    &.open {
      max-height: ${Object.keys(MENU_LANGUAGES).length * 85}px;
      visibility: visible;
    }
  }
`;
