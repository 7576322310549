import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import styled from 'styled-components';
import { StyledLessonExerciseHint as LessonExerciseHint } from '../LessonExerciseHint/LessonExerciseHint.jsx';
import { StyledSummary as Summary } from './Summary.jsx';
import { filteredAssembledInfos } from '../../../../utils/lessonUtils';
import { userIsLoggedIn } from '../../../../state/user/userSelectors';
import { useI18nContext } from '../../../../context/I18nContext.jsx';
import { removeImproveMode, setImproveMode } from '../../../../state/mode/lessonExerciseModeDuck';
import { useExerciseManager } from '../../../../hooks/useExerciseManager';
import {
  getLoginUrl,
  getSummaryUrl,
  LESSON_EXERCISE_RESULT_SUFFIX,
} from '../../../../utils/url/urlFactory';
import { setLessonProgressSyncNeeded } from '../../../../state/progress/learnProgressDuck';
import { PageMetaData } from '../../../Page/PageMetaData.jsx';
import { PAGE_TYPES } from '../../../../constants/pageTypes';
import { GtmScriptWithDataLayer } from '../../../GoogleTagManager';
import { mediaMin } from '../../../../utils/css';
import { LinkButton } from '../../../LinkButton/LinkButton.jsx';
import { gridSpacingForResults } from '../utils/exerciseCustomCSS';
import { DetailIntroText } from '../../../Text/Text.jsx';
import { useLanguage } from '../../../../context/LanguageContext';

export const LessonExerciseResult = ({
  className,
  lessonOverviewParts,
  lessonId,
  lessonName,
  lessonKeywordStrings,
  data,
  lessonPath,
  lastPublicationDate,
  firstPublicationDate,
}) => {
  const isLoggedIn = useSelector(userIsLoggedIn);
  const { i18n } = useI18nContext();
  const dispatch = useDispatch();
  const { langCode } = useLanguage();

  const { firstExerciseLink, isFullScoreAchieved } = useExerciseManager();

  useEffect(() => {
    dispatch(removeImproveMode(lessonId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const assembledInfos = filteredAssembledInfos({ lessonOverviewParts, lessonPath });
  const showImproveButton = !isLoggedIn || (isLoggedIn && !isFullScoreAchieved);
  const resultPrefix = 'lesson.exercises.result';

  const exerciseInfos = assembledInfos
    .map(info => i18n.t(`${resultPrefix}.${info.name}`))
    .join(', ');

  const nextUrl = assembledInfos[0] ? assembledInfos[0].url : getSummaryUrl(lessonPath);
  const hintKey = i18n.t(`${resultPrefix}.hint`);

  return (
    <div className={className}>
      <PageMetaData
        pageType={PAGE_TYPES.LESSON_EXERCISE_RESULT}
        content={{
          name: lessonName,
          keywordStrings: lessonKeywordStrings,
          lastPublicationDate,
          firstPublicationDate,
        }}
        translationFirst
        relativeCanonicalUrl={`${lessonPath}/${LESSON_EXERCISE_RESULT_SUFFIX}`}
      />
      <GtmScriptWithDataLayer content={data} />
      <div className="result-container">
        <Summary lessonId={lessonId} showFeedback />
        <div className="result-infos">
          {exerciseInfos && (
            <DetailIntroText>
              {i18n.t(`${resultPrefix}.whatsNext`)} {exerciseInfos}.
            </DetailIntroText>
          )}
        </div>
        {!isLoggedIn && <LessonExerciseHint description={hintKey} isSimpleIconHint isA="p" />}
      </div>
      <div className="result-actions">
        {showImproveButton && (
          <LinkButton
            variant="secondary"
            data-testid="lessonExerciseResultImproveButton"
            data-tracking-name={!isLoggedIn ? 'linkToLogin' : 'improve'}
            titleKey={`lesson.exercises.result.${isLoggedIn ? 'correct' : 'loginFixErrors'}`}
            onClick={() =>
              dispatch(isLoggedIn ? setImproveMode(lessonId) : setLessonProgressSyncNeeded(true))
            }
            to={isLoggedIn ? firstExerciseLink(true) : getLoginUrl(langCode)}
          />
        )}
        {!isLoggedIn && (
          <LinkButton
            variant="secondary"
            data-testid="lessonExerciseResultContinueButton"
            titleKey="lesson.exercises.result.notSave"
            to={nextUrl}
          />
        )}
        <LinkButton
          variant="primary"
          data-testid="lessonExerciseResultSaveButton"
          data-tracking-name={!isLoggedIn ? 'linkToLogin' : 'save'}
          titleKey={`lesson.exercises.result.${isLoggedIn ? 'save' : 'loginSave'}`}
          onClick={isLoggedIn ? undefined : () => dispatch(setLessonProgressSyncNeeded(true))}
          to={isLoggedIn ? nextUrl : getLoginUrl(langCode)}
        />
      </div>
    </div>
  );
};

export const StyledLessonExerciseResult = styled(LessonExerciseResult)`
  padding-bottom: 40px;

  ${gridSpacingForResults}

  .result-container {
    margin-bottom: 50px;
  }

  .result-infos {
    text-align: center;
    margin: 40px 0;
  }

  .result-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 10px;
    margin-bottom: 50px;
    gap: 1rem;

    ${mediaMin.xl`
      flex-direction: row;
    `}
  }
`;
