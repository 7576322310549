import styled from 'styled-components';
import classnames from 'classnames';
import { colors, mediaMax } from '../../../../utils/css';
import { StyledLessonItem as LessonItem } from '../LessonItem/LessonItem.jsx';

export const LessonList = ({ className, groupedLessons, onClickReset, isOpen = true }) => (
  // eslint-disable-next-line jsx-a11y/role-supports-aria-props
  <ul className={classnames(className, { collapsed: !isOpen })} aria-expanded={isOpen}>
    <div className="content">
      {groupedLessons.map(lesson => (
        <li key={lesson.id}>
          <LessonItem lesson={lesson.target} onClickReset={onClickReset} />
        </li>
      ))}
    </div>
  </ul>
);

export const StyledLessonList = styled(LessonList)`
  display: grid;
  grid-template-rows: 1fr;
  transition: grid-template-rows 0.3s ease-out;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;

  > .content {
    min-height: 0;
  }

  > div > li {
    margin: 0;
  }

  ${mediaMax.md`
    background-color: ${colors.BLUE_GREY_01};

    &:last-child {
      border-bottom: 0;
    }
    
    &.collapsed {
      grid-template-rows: 0fr;
    }
  `}
`;
