import { PAGE_TYPES } from '../constants/pageTypes';

const LESSON_MENU_VOCABULARY_KEY = 'lesson.menu.vocabulary';
const LESSON_EXERCISE_RESULT_TITLE_KEY = 'lesson.exercises.result.title';
const LESSON_MENU_GRAMMAR_KEY = 'lesson.menu.grammar';
const LESSON_MENU_REGIONAL_STUDIES_KEY = 'lesson.menu.regionalStudies';
const LESSON_MANUSCRIPT_KEY = 'lesson.menu.manuscript';
const LESSON_MENU_EXTRAS_KEY = 'lesson.menu.extras';
const LESSON_EXERCISES_RESULT_GENERAL_KEY = 'lesson.exercises.result.general';

export const PAGE_TYPE_TO_TITLE_TRANSLATION_KEYS = Object.freeze({
  [PAGE_TYPES.ALPHABETICAL_ORDER]: ['lesson.menu.alphabeticalOrder'],
  [PAGE_TYPES.GRAMMAR_DETAIL]: [LESSON_MENU_GRAMMAR_KEY],
  [PAGE_TYPES.HELP]: ['common.help'],
  [PAGE_TYPES.VOCABULARY_OVERVIEW]: [LESSON_MENU_VOCABULARY_KEY],
  [PAGE_TYPES.USER_PROFILE]: ['profile.edit.title'],
  [PAGE_TYPES.FEEDBACK_STATUS]: ['common.feedback'],
  [PAGE_TYPES.REGISTER_USER]: ['profile.register.title'],
  [PAGE_TYPES.VOCABULARY]: [LESSON_MENU_VOCABULARY_KEY],
  [PAGE_TYPES.LEGAL]: ['metadata.legal'],
  [PAGE_TYPES.LESSON_EXERCISE_RESULT]: [LESSON_EXERCISE_RESULT_TITLE_KEY],
  [PAGE_TYPES.LESSON_SUMMARY]: [LESSON_EXERCISE_RESULT_TITLE_KEY],
  [PAGE_TYPES.GRAMMAR]: [LESSON_MENU_GRAMMAR_KEY],
  [PAGE_TYPES.GRAMMAR_OVERVIEW]: [LESSON_MENU_GRAMMAR_KEY],
  [PAGE_TYPES.REGIONAL_STUDIES]: [LESSON_MENU_REGIONAL_STUDIES_KEY],
  [PAGE_TYPES.MANUSCRIPT]: [LESSON_MANUSCRIPT_KEY],
  [PAGE_TYPES.EXTRAS]: [LESSON_MENU_EXTRAS_KEY],
  [PAGE_TYPES.VOCABULARY_TRAINER]: ['header.menu.vocabularyTrainer'],
  [PAGE_TYPES.PLACEMENT_DASHBOARD]: ['header.menu.levelTest'],
  [PAGE_TYPES.PASSWORD_CHANGE]: ['profile.edit.password.change.title'],
  [PAGE_TYPES.PASSWORD_RESET]: ['profile.edit.password.reset.title'],
  [PAGE_TYPES.PASSWORD_SET]: ['profile.edit.password.set.title'],
  [PAGE_TYPES.FINAL_TEST]: [LESSON_EXERCISES_RESULT_GENERAL_KEY],
  [PAGE_TYPES.PLACEMENT_TEST]: [LESSON_EXERCISES_RESULT_GENERAL_KEY, 'header.menu.levelTest'],
  [PAGE_TYPES.LOGIN]: ['profile.login.title'],
});

export const PAGE_TYPE_TO_DESCRIPTION_TRANSLATION_KEYS = Object.freeze({
  [PAGE_TYPES.HELP]: 'metadata.description.help',
  [PAGE_TYPES.VOCABULARY]: 'metadata.description.vocabulary',
  [PAGE_TYPES.LEGAL]: 'metadata.description.legal',
  [PAGE_TYPES.GRAMMAR]: 'metadata.description.grammar',
  [PAGE_TYPES.REGIONAL_STUDIES]: 'metadata.description.regionalStudies',
  [PAGE_TYPES.MANUSCRIPT]: 'metadata.description.manuscript',
  [PAGE_TYPES.REGISTER_USER]: 'metadata.description.register',
  [PAGE_TYPES.EXTRAS]: 'metadata.description.extras',
  [PAGE_TYPES.LOGIN]: 'metadata.description.login',
});

export const PAGE_TYPE_TO_KEYWORDS_TRANSLATION_KEYS = Object.freeze({
  [PAGE_TYPES.VOCABULARY]: LESSON_MENU_VOCABULARY_KEY,
  [PAGE_TYPES.LESSON_EXERCISE_RESULT]: LESSON_EXERCISE_RESULT_TITLE_KEY,
  [PAGE_TYPES.LESSON_SUMMARY]: LESSON_EXERCISE_RESULT_TITLE_KEY,
  [PAGE_TYPES.GRAMMAR]: LESSON_MENU_GRAMMAR_KEY,
  [PAGE_TYPES.GRAMMAR_OVERVIEW]: LESSON_MENU_GRAMMAR_KEY,
  [PAGE_TYPES.GRAMMAR_DETAIL]: LESSON_MENU_GRAMMAR_KEY,
  [PAGE_TYPES.VOCABULARY_OVERVIEW]: LESSON_MENU_VOCABULARY_KEY,
  [PAGE_TYPES.REGIONAL_STUDIES]: LESSON_MENU_REGIONAL_STUDIES_KEY,
  [PAGE_TYPES.MANUSCRIPT]: LESSON_MANUSCRIPT_KEY,
  [PAGE_TYPES.EXTRAS]: LESSON_MENU_EXTRAS_KEY,
  [PAGE_TYPES.FINAL_TEST]: LESSON_EXERCISES_RESULT_GENERAL_KEY,
  [PAGE_TYPES.PLACEMENT_TEST]: LESSON_EXERCISES_RESULT_GENERAL_KEY,
});

export const createTitle = ({ titleParts }) => titleParts.filter(Boolean).join(' | ');
export const createKeywords = ({ keywords }) => keywords.filter(Boolean).join(',');

export const html2text = ({ html }) => (html ? html.replace(/<.*?>/g, ' ') : html);
