import styled from 'styled-components';
import { ContentBlockHeadline } from '../../Headline/Headline.jsx';
import { I18nText } from '../../I18n/I18nText.jsx';

export const ContentSection = styled(({ title, children, className }) => (
  <div className={className}>
    <I18nText isA={ContentBlockHeadline} as="h3" translation={title} />
    <div>{children}</div>
  </div>
))`
  > ${ContentBlockHeadline} {
    margin-top: 0;
  }
  > div {
    margin-bottom: 1rem;
  }
`;
