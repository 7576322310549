import styled from 'styled-components';
import { StyledRichText as RichText } from '../RichText/RichText.jsx';
import { columnSize, mediaMin } from '../../utils/css';
import { VocabularyItemText } from '../Text/Text.jsx';

export const VocabularyItem = ({ className, item }) => {
  return (
    <div className={className}>
      <VocabularyItemText as="div">
        <h4>{item.name}</h4>
        {item.subTitle && <p>{item.subTitle}</p>}
      </VocabularyItemText>
      {item.text && <RichText isA="div" content={item.text} noContainer />}
    </div>
  );
};

export const StyledVocabularyItem = styled(VocabularyItem)`
  > *:first-child {
    /* @noflip */
    direction: ltr;
    /* @noflip */
    text-align: left;
  }

  padding: 1rem ${columnSize.c1} 1rem;

  > ${VocabularyItemText} {
    > h4 {
      margin: 0;
    }
    > p {
      margin: 0.5rem 0 0 0;
    }
  }
  ${mediaMin.md`
    padding-left: ${columnSize.c1};
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${columnSize.c6}
    
    .item {
      padding-left: ${columnSize.c1};
    }
  `}

  ${mediaMin.md`
    gap: ${columnSize.c4}
  `}
`;
