import styled from 'styled-components';
import IconHintSimple from '../../../../assets/svg/icon-hint.svg';
import IconHintCircleOrange from '../../../../assets/svg/icon-hint-circle-orange.svg';
import { StyledRichText as RichText } from '../../../RichText/RichText.jsx';
import { columnSize, mediaMin } from '../../../../utils/css';
import { useTranslation } from '../../../../hooks/useTranslation';

export const LessonExerciseHint = ({
  description,
  isSimpleIconHint = false,
  isA = 'div',
  className,
}) => {
  const translatedText = useTranslation(description);
  const hintSVGSource = isSimpleIconHint ? IconHintSimple : IconHintCircleOrange;

  return (
    description && (
      <div className={`hint ${className}`}>
        <img alt="icon" src={hintSVGSource} width="18" height="18" />
        <RichText isA={isA} dir="auto" content={translatedText} noContainer />
      </div>
    )
  );
};

export const StyledLessonExerciseHint = styled(LessonExerciseHint)`
  display: flex;
  padding: 0 ${columnSize.c1};
  flex-direction: column;
  align-items: center;
  direction: ltr;

  img {
    margin: 0 0 1rem;
  }

  ${mediaMin.md`
    padding: 0 ${columnSize.c1};
    flex-direction: row;
    justify-content: left;
    img {
      margin: 0 0.5rem;
    }
  `}

  p {
    text-align: center;

    ${mediaMin.md`
      text-align: left;
    `}
  }

  p:last-child {
    margin-bottom: 0;
  }
`;
