import { Helmet } from 'react-helmet-async';
import { resolutions } from '../../utils/css';
import { formatLg, replaceFormatInUrl } from '../../utils/imgUtils';

// @used in ROAD
export const HeroImagePreloader = ({ image, formatConfig }) => {
  if (!image || !formatConfig) {
    return null;
  }

  const imgFormats = Object.values(formatConfig)
    .map(({ formatGroupName, minWidth, maxWidth }) => {
      const formatsForCurrentGroup = formatLg[formatGroupName].formats;
      const formatsThatFitBreakpoint = formatsForCurrentGroup.filter(
        imgFormat => imgFormat.width >= minWidth && imgFormat.width <= maxWidth,
      );

      const lastImgFormatForBreakpoint =
        formatsThatFitBreakpoint[formatsThatFitBreakpoint.length - 1];
      if (lastImgFormatForBreakpoint.width < maxWidth && maxWidth !== resolutions.max.xl) {
        const indexInFormats = formatsForCurrentGroup.indexOf(lastImgFormatForBreakpoint);
        const nextFormat = formatsForCurrentGroup[indexInFormats + 1];
        if (nextFormat) {
          return [
            ...formatsThatFitBreakpoint,
            {
              ...nextFormat,
              width: maxWidth,
            },
          ];
        }
      }
      return formatsThatFitBreakpoint;
    })
    .flat();

  return (
    <Helmet>
      {imgFormats.map((imgFormat, index) => {
        const prevImgWidth = imgFormats[index - 1]?.width;
        const notLast = index !== imgFormats.length - 1;
        const mediaMin = prevImgWidth ? prevImgWidth + 1 : 0;
        const mediaMax = imgFormat.width;
        return (
          <link
            key={imgFormat.id}
            rel="preload"
            /* eslint-disable-next-line react/no-unknown-property */
            fetchPriority="high"
            as="image"
            media={`(min-width: ${mediaMin}px) ${notLast ? `and (max-width: ${mediaMax}px)` : ''}`}
            href={replaceFormatInUrl(image.staticUrl, imgFormat.id)}
          />
        );
      })}
    </Helmet>
  );
};
