import styled from 'styled-components';
import { useSelector } from 'react-redux';
import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { mediaMax, colors, columnSize } from '../../../../utils/css';
import { StyledLessonScore as LessonScore } from './LessonScore.jsx';
import { StyledSmallProgressResetButton as ProgressResetButton } from '../../Progress/ProgressResetButton.jsx';
import { LearnProgressIcon } from '../../../LearnProgressIcon/LearnProgressIcon.jsx';
import { findLessonLearnProgressSelector } from '../../../../state/progress/learnProgressSelectors';
import { Link } from '../../../Link/Link.jsx';
import { TeaserHeadline } from '../../../Headline/Headline.jsx';
import { TeaserText } from '../../../Text/Text.jsx';

const fragmentName = 'LessonDetails';
export const lessonDetails = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on Lesson {
        id
        shortTitle
        learningTargetHeadline
        grammarDescription
        namedUrl
      }
    `,
  ),
};

export const LessonItem = ({ lesson, className, onClickReset }) => {
  const { id: lessonId, namedUrl, shortTitle, learningTargetHeadline, grammarDescription } = lesson;
  const lessonProgress = useSelector(findLessonLearnProgressSelector(lessonId));

  const onHandleResetClick = e => {
    onClickReset(e, lessonId);
  };

  return (
    <Link className={className} to={namedUrl} tabIndex={0}>
      <div className="content">
        <TeaserHeadline>{shortTitle}</TeaserHeadline>
        <TeaserText className="learningTarget">{learningTargetHeadline}</TeaserText>
        <TeaserText className="categories">{grammarDescription}</TeaserText>
      </div>
      <div className="progress">
        <LearnProgressIcon learnProgress={lessonProgress} />
        {lessonProgress && (
          <LessonScore
            lessonProgress={lessonProgress}
            onClickReset={onClickReset}
            lessonId={lessonId}
          />
        )}
        {lessonProgress && <ProgressResetButton onClickReset={onHandleResetClick} />}
      </div>
    </Link>
  );
};

export const StyledLessonItem = styled(LessonItem)`
  border-top: 1px solid ${colors.LG_GRAY_TRANSPARENT_1};
  display: block;
  position: relative;
  height: 9rem;
  padding: 1rem ${columnSize.c1} 3rem;
  text-decoration: none;
  overflow: hidden;

  ${mediaMax.md`
    height: 7rem;
    padding: 1rem ${columnSize.c1};
  `}

  ${mediaMax.xs`
    padding: 1rem ${columnSize.c2};
  `}
  
  &:hover {
    text-decoration: none;
  }

  > .content {
    display: grid;
    grid-template-rows: auto 1.5rem 1.5rem;
    height: 100%;

    > * {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      align-self: start;
    }

    > ${TeaserHeadline} {
      padding-bottom: 0.5rem;
      align-self: start;
      margin: 0;
    }
  }

  > .progress {
    position: absolute;
    top: 1rem;
    right: ${columnSize.c1};
    bottom: 3rem;
    display: grid;
    grid-template-rows: 1.5rem 1.5rem 1.5rem;
    align-items: center;
    justify-items: end;

    ${mediaMax.xs`
      right: ${columnSize.c2};
    `}
  }

  svg,
  ${ProgressResetButton} {
    margin: 0;
  }
`;
