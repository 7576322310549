import styled from 'styled-components';
import { memoizeWith } from 'ramda';
import gql from 'graphql-tag';
import classnames from 'classnames';
import { colors, columnSize, mediaMin } from '../../../utils/css';
import { ContentBlockHeadline } from '../../Headline/Headline.jsx';

const fragmentName = 'subHeaderFragment';
export const subHeaderFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on CompositionComponentConfiguration {
        freeTitle
      }
    `,
  ),
};

export const SubHeaderBlock = ({ className, configuration: { freeTitle }, background }) => {
  return (
    <div className={classnames(className, background)}>
      <ContentBlockHeadline>{freeTitle}</ContentBlockHeadline>
    </div>
  );
};

export const StyledSubHeaderBlock = styled(SubHeaderBlock)`
  min-height: 1rem;
  display: flex;
  align-items: center;
  height: max-content;

  &.white {
    background-color: ${colors.LG_WHITE};
  }
  &.grey {
    background-color: ${colors.BLUE_GREY_01};
  }
  &.blue {
    background-color: ${colors.DARK_BLUE_GREY_01};
  }

  ${mediaMin.xl`
    min-height: 2rem;
  `}

  ${ContentBlockHeadline} {
    margin-top: 2rem;
    margin-bottom: 0;
    padding-left: ${columnSize.c2};
    padding-right: ${columnSize.c2};
  }
`;
