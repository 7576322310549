import styled from 'styled-components';
import { findFeedbackAudio } from '../../../../../utils/audioUtils';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { StyledLessonExerciseQuestionAudio as LessonExerciseQuestionAudio } from '../../LessonExerciseQuestion/QuestionTypes/LessonExerciseQuestionAudio.jsx';
import { StyledLessonExerciseQuestionText as LessonExerciseQuestionText } from '../../LessonExerciseQuestion/QuestionTypes/LessonExerciseQuestionText.jsx';
import { mediaMin } from '../../../../../utils/css';
import { useLanguage } from '../../../../../context/LanguageContext';

export const SelfAssessmentQuestion = ({ className, audios, mainContentImage }) => {
  const howWasItWithOnlyFeedbackAudio = useTranslation('exercise.howWasItWithOnlyFeedbackAudio');
  const howWasItLabel = useTranslation('exercise.howWasIt');

  const { isRtl } = useLanguage();

  const isSolutionAudioFallbackMode = Boolean(mainContentImage);
  const feedbackAudio = findFeedbackAudio(isSolutionAudioFallbackMode, audios);

  const feedbackAudioUrl = feedbackAudio ? feedbackAudio.target.mp3Src : null;

  const lessonExerciseQuestionAudio = () => {
    const hasOnlyFeedbackAudio =
      feedbackAudioUrl.includes('Loesungsaudio.mp3') && audios.length === 1;
    const audioLabel = hasOnlyFeedbackAudio ? howWasItWithOnlyFeedbackAudio : howWasItLabel;

    return (
      <LessonExerciseQuestionAudio audio={feedbackAudio} inquiryText={audioLabel} isRtl={isRtl} />
    );
  };

  return (
    <div className={className}>
      {feedbackAudioUrl ? (
        lessonExerciseQuestionAudio()
      ) : (
        <LessonExerciseQuestionText inquiryText={howWasItLabel} />
      )}
    </div>
  );
};

export const StyledSelfAssessmentQuestion = styled(SelfAssessmentQuestion)`
  & > ${LessonExerciseQuestionText} {
    width: 100%;
  }

  ${mediaMin.md`
      max-height: min-content;
  `}
`;
