import styled from 'styled-components';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { I18nText } from '../I18n/I18nText.jsx';
import { DetailPageHeadline } from '../Headline/Headline.jsx';
import { DetailIntroText } from '../Text/Text.jsx';
import { StyledInput } from '../FormElements/Input/StyledInput.jsx';
import { StyledStandardButton } from '../StandardButton/StandardButton.jsx';
import { emailPattern } from '../../utils/validation/validators';
import { colors, columnSize, mediaMin } from '../../utils/css';
import { LinkButton } from '../LinkButton/LinkButton.jsx';
import { getPasswordResetUrl, getRegisterUrl } from '../../utils/url/urlFactory';
import { useLanguage } from '../../context/LanguageContext';
import { StyledValidationMessage } from '../FormElements/ValidationMessage.jsx';
import { feedbackHelper } from '../../utils/url/feedbackHelpers';
import { login } from '../../services/userService';
import { UserPageLayout } from './UserPageLayout.jsx';

const Login = ({ className }) => {
  const { langCode } = useLanguage();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const abortController = useMemo(() => new AbortController(), []);

  useEffect(() => () => abortController.abort('unmount'), [abortController]);

  const formMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });
  const {
    handleSubmit,
    setError,
    formState: { errors },
  } = formMethods;

  const onSubmit = async data => {
    setLoading(true);

    try {
      const response = await login({
        body: JSON.stringify(data),
        signal: abortController.signal,
      });

      if (response.status === 200) {
        history.push(feedbackHelper.forLogin(langCode));
        return;
      }

      const body = await response.json();

      switch (body.error) {
        case 'invalid credentials':
          setError('root.result', { message: 'invalidCredentials' });
          break;
        case 'registration incomplete':
          history.push(feedbackHelper.forForcedLogout(langCode));
          break;
        case 'username required':
          setError('username', { type: 'required' });
          break;
        case 'password required':
          setError('password', { type: 'required' });
          break;
        default:
          history.push(feedbackHelper.forGeneralError(langCode));
      }
    } catch (err) {
      if (err !== 'unmount') {
        history.push(feedbackHelper.forGeneralError(langCode));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <UserPageLayout className={className}>
      <I18nText isA={DetailPageHeadline} translation="profile.login.title" />
      <I18nText isA={DetailIntroText} translation="profile.login.subTitle" />
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledInput
            name="username"
            labelKey="profile.login.email"
            registerOptions={{ required: true, pattern: emailPattern }}
          />
          <StyledInput
            labelKey="profile.login.password"
            name="password"
            type="password"
            registerOptions={{ required: true }}
          />
          {errors.root?.result?.message && (
            <I18nText
              isA={StyledValidationMessage}
              translation={`profile.login.${errors.root.result.message}`}
            />
          )}
          <StyledStandardButton
            titleKey="profile.login.submit"
            disabled={loading}
            data-testid="loginButton"
          />
        </form>
      </FormProvider>
      <hr />
      <div>
        <LinkButton
          variant="secondary"
          titleKey="profile.login.register"
          to={getRegisterUrl(langCode)}
          data-testid="registerButton"
        />
        <LinkButton
          variant="secondary"
          titleKey="profile.login.forgotPassword"
          to={getPasswordResetUrl(langCode)}
          data-testid="forgotPasswordButton"
        />
      </div>
    </UserPageLayout>
  );
};

export const StyledLogin = styled(Login)`
  > hr {
    margin: 0 0 1rem;
    border: 0;
    border-top: 1px solid ${colors.BLUE_GREY_02};
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > ${LinkButton} {
      flex: 1;
    }

    ${mediaMin.lg`
      flex-direction: row;
      gap: ${columnSize.c1};
    `}
  }
`;
