/* eslint-disable no-nested-ternary */
import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import {
  MediaInputVideo,
  mediaInputVideoFragment,
} from '../../MediaInput/MediaInputVideo/MediaInputVideo.jsx';
import {
  MediaInputImage,
  mediaInputImageFragment,
} from '../../MediaInput/MediaInputImage/MediaInputImage.jsx';
import {
  MediaInputAudio,
  mediaInputAudioFragment,
} from '../../MediaInput/MediaInputAudio/MediaInputAudio.jsx';
import { findAudioWithContentLinkMatching } from '../../../utils/audioUtils';
import { ARTICLE_KIND_ENUM } from '../../../constants/articleKind';
import { StyledHeroImage as HeroImage } from '../../HeroImage/HeroImage.jsx';

const fragmentName = ({ type }) => `${type}MediaInputChooser`;
export const mediaInputChooserFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    ({ type }) => type,
    ({ type }) => gql`
      fragment ${fragmentName({ type })} on ${type} {
        ${type === 'Article' ? 'kind' : ''}
        ...${mediaInputImageFragment.name({ type })}
        ...${mediaInputAudioFragment.name({ type })}
        ...${mediaInputVideoFragment.name({ type })}
      }
      ${mediaInputImageFragment.fragment({ type })}
      ${mediaInputAudioFragment.fragment({ type })}
      ${mediaInputVideoFragment.fragment({ type })}
    `,
  ),
};

// description audio has mainContent === true, input audio has mainContent === false
const conditionForAudioInput = content =>
  findAudioWithContentLinkMatching(cl => !cl.mainContent, content);

const articleKindToComponent = {
  [ARTICLE_KIND_ENUM.AUDIO]: {
    component: MediaInputAudio,
    condition: conditionForAudioInput,
  },
  [ARTICLE_KIND_ENUM.VIDEO]: {
    component: MediaInputVideo,
    condition: ({ videos }) => videos.length > 0,
  },
  [ARTICLE_KIND_ENUM.STANDARD]: {
    component: MediaInputImage,
    condition: ({ mainContentImage }) => !!mainContentImage,
  },
};

const findInputComponent = ({ data, heroImage }) => {
  const { videos, mainContentImage, mainContentImageLink, kind: articleKind } = data.content;

  if (articleKind) {
    const articleKindConfiguration = articleKindToComponent[articleKind];
    if (articleKindConfiguration && articleKindConfiguration.condition(data.content)) {
      const ArticleKindComponent = articleKindConfiguration.component;
      return <ArticleKindComponent data={data} />;
    }
  }

  const inputAudio = conditionForAudioInput(data.content);

  return videos.length > 0 ? (
    <MediaInputVideo data={data} />
  ) : inputAudio !== undefined ? (
    <MediaInputAudio data={data} />
  ) : mainContentImage ? (
    heroImage ? (
      <HeroImage mainContentImageLink={mainContentImageLink} />
    ) : (
      <MediaInputImage data={data} />
    )
  ) : null;
};

export const MediaInputChooser = ({ data, heroImage }) => {
  const inputComponent = findInputComponent({ data, heroImage });

  return <section className="media">{inputComponent}</section>;
};
