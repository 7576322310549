import gql from 'graphql-tag';
import { useCallback, useEffect, useRef } from 'react';
import { memoizeWith } from 'ramda';
import styled from 'styled-components';
import { StyledRichText as RichText } from '../../../RichText/RichText.jsx';
import { findAudioWithContentLinkMatching } from '../../../../utils/audioUtils';
import { useAudio } from '../../../../hooks/useAudio';
import { I18nText } from '../../../I18n/I18nText.jsx';
import { RichTextHeadline } from '../../../Headline/Headline.jsx';
import { ReactComponent as ReadIcon } from '../../../../assets/svg/icon-read.svg';
import { colors } from '../../../../utils/css';

const fragmentName = 'LessonExerciseHeadline';
export const lessonExerciseHeadlineFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on Exercise {
        name
        description
        audios {
          id
          mp3Src
        }
        contentLinks {
          targetId
          mainContent
        }
      }
    `,
  ),
};

export const LessonExerciseHeadline = ({ className, data }) => {
  const { name, description } = data.content;
  // description audio has mainContent === true, input audio has mainContent === false
  const descriptionMp3Src = findAudioWithContentLinkMatching(
    cl => cl.mainContent,
    data.content,
  )?.mp3Src;
  const { addRef, playAudio } = useAudio();
  const audioRef = useRef();

  const onAnswerClicked = useCallback(
    event => {
      playAudio(event, audioRef);
    },
    [playAudio],
  );

  useEffect(() => (descriptionMp3Src ? addRef(audioRef) : undefined), [descriptionMp3Src, addRef]);

  return (
    <div className={className}>
      {descriptionMp3Src ? (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a onClick={onAnswerClicked} className="audio-modal-link audio-example" href="#">
          <I18nText isA={RichTextHeadline} translation={name} />
          <ReadIcon />
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <audio
            ref={audioRef}
            className="audio-modal-file"
            tabIndex="0"
            width="100%"
            preload="none"
          >
            <source src={descriptionMp3Src} type="audio/mpeg" />
          </audio>
        </a>
      ) : (
        <I18nText isA={RichTextHeadline} translation={name} />
      )}
      <RichText isA="div" dir="auto" content={description} noContainer />
    </div>
  );
};

export const StyledLessonExerciseHeadline = styled(LessonExerciseHeadline)`
  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 1rem;
    svg {
      margin-block-start: 0.65rem;
    }
  }

  a,
  h2 {
    color: ${colors.DW_DARK_BLUE_NEW};
  }
  ${RichTextHeadline} {
    margin: 0.5rem 0 1rem;
  }
  ${RichText} {
    p:last-child {
      margin-bottom: 0;
    }
  }
`;
