import { cloneElement, useCallback, useState } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

import { StyledWithDraggable as WithDraggable } from '../commons/WithDraggable.jsx';
import { colors, mediaMin } from '../../utils/css';
import { StyledSlider as Slider } from './Slider.jsx';

import { StyledCarouselArrows as CarouselArrows } from './CarouselArrows.jsx';
import { StyledIndicator as Indicator } from './Indicator.jsx';
import { DarkThemeProvider } from '../../context/ThemeContext.jsx';

// @used in ROAD
export const Carousel = ({ className, children = [], isRtl }) => {
  const maxIndex = children.length - 1;

  const [currentIndex, setCurrentIndex] = useState(0);
  const next = useCallback(() => setCurrentIndex(old => Math.min(old + 1, maxIndex)), [maxIndex]);
  const prev = useCallback(() => setCurrentIndex(old => Math.max(old - 1, 0)), []);

  const hasPositionIndicators = children.length > 1;
  const hasPositionIndicatorsOnMdPlus = children.length > 2;

  const isFirstSlide = currentIndex === 0;
  const isLastSlide = currentIndex >= maxIndex;

  const nextVisibleIndex = () => {
    if (!isLastSlide) {
      next();
    }
  };

  return (
    <DarkThemeProvider>
      <div className={className} tabIndex={-1} dir={isRtl ? 'rtl' : 'ltr'}>
        <WithDraggable isRtl={isRtl} prev={prev} next={nextVisibleIndex}>
          {draggableRef => (
            <Slider
              isRtl={isRtl}
              className={classnames({
                'has-indicator-xs': hasPositionIndicators,
                'has-indicator-md': hasPositionIndicatorsOnMdPlus,
              })}
              currentIndex={currentIndex}
              ref={draggableRef}
            >
              {children.map((child, index) => {
                const isHidden = index !== currentIndex;
                return cloneElement(child, {
                  'aria-hidden': isHidden,
                  tabIndex: isHidden ? '-1' : '0',
                });
              })}
            </Slider>
          )}
        </WithDraggable>
        {hasPositionIndicators && (
          <>
            <div className="carousel-icons">
              <Indicator slideNumber={currentIndex + 1} slidesCount={children.length} />
            </div>
            <CarouselArrows
              isRtl={isRtl}
              disablePrevArrow={isFirstSlide}
              disableNextArrow={isLastSlide}
              onPrev={prev}
              onNext={next}
            />
          </>
        )}
      </div>
    </DarkThemeProvider>
  );
};

export const StyledCarousel = styled(Carousel)`
  background-color: ${colors.DARK_BLUE_GREY_01};
  padding: 0;
  overflow-x: hidden;
  position: relative;

  &:focus {
    /* having tabindex="-1" on element makes it click focusable */
    outline: none;
  }

  .carousel-icons {
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translate(-50%, 0);
    min-width: 50%;

    svg {
      display: block;
    }

    ${Indicator} {
      /* take as little space as possible to make drag possible just next to it */
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
      position: static;
      transform: translate(0);
      margin: 0;
    }
  }

  ${mediaMin.sm`
    .carousel-icons {
      min-width: 33.3333%;
    }
  `}
`;
