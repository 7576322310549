import { getFeedbackUrl } from './urlFactory';
import { FEEDBACK_TYPES } from '../../constants/feedback';

export const feedbackHelper = {
  getForErrorCode(errorResponse, langCode) {
    return feedbackHelper.error(errorResponse?.errorCode, langCode);
  },
  forLogin(langCode) {
    return getFeedbackUrl(FEEDBACK_TYPES.LOGIN, langCode);
  },
  forLogout(langCode) {
    return getFeedbackUrl(FEEDBACK_TYPES.LOGOUT, langCode);
  },
  forUserDelete(langCode) {
    return getFeedbackUrl(FEEDBACK_TYPES.DELETED, langCode);
  },
  forInvalidToken(langCode) {
    return getFeedbackUrl(FEEDBACK_TYPES.INVALID_TOKEN, langCode);
  },
  forSessionTimeout(redirectUrl, langCode) {
    return getFeedbackUrl(FEEDBACK_TYPES.SESSION_TIMEOUT, langCode);
  },
  forForcedLogout(langCode) {
    return getFeedbackUrl(FEEDBACK_TYPES.FORCED_LOGOUT, langCode);
  },
  forPasswordChanged(langCode) {
    return getFeedbackUrl(FEEDBACK_TYPES.PASSWORD_CHANGED, langCode);
  },
  forMailChanged(langCode) {
    return getFeedbackUrl(FEEDBACK_TYPES.MAIL_CHANGED, langCode);
  },
  forMailChangeRequest(langCode) {
    return getFeedbackUrl(FEEDBACK_TYPES.MAIL_CHANGE_REQUEST, langCode);
  },
  forRegistered(langCode) {
    return getFeedbackUrl(FEEDBACK_TYPES.REGISTERED, langCode);
  },
  forRegistrationConfirm(langCode) {
    return getFeedbackUrl(FEEDBACK_TYPES.REGISTRATION_CONFIRMED, langCode);
  },
  forPasswordResetRequest(langCode) {
    return getFeedbackUrl(FEEDBACK_TYPES.PASSWORD_RESET_REQUESTED, langCode);
  },
  forSetPassword(langCode) {
    return getFeedbackUrl(FEEDBACK_TYPES.PASSWORD_RESET_SET, langCode);
  },
  forProfileUpdated(langCode) {
    return getFeedbackUrl(FEEDBACK_TYPES.PROFILE_UPDATED, langCode);
  },
  forCertificateMissingTranscription(langCode) {
    return getFeedbackUrl(FEEDBACK_TYPES.CERTIFICATE_NO_TRANSCRIPTION, langCode);
  },
  forInvalidCertificate(langCode) {
    return getFeedbackUrl(FEEDBACK_TYPES.INVALID_CERTIFICATE, langCode);
  },
  forGeneralError(langCode) {
    return getFeedbackUrl(FEEDBACK_TYPES.OTHER, langCode);
  },
  error(errorCode, langCode) {
    return getFeedbackUrl(errorCode || FEEDBACK_TYPES.OTHER, langCode);
  },
  errorPasswordChange(errorCode, langCode) {
    return getFeedbackUrl(
      errorCode ? FEEDBACK_TYPES.PASSWORD_CHANGE + errorCode : FEEDBACK_TYPES.OTHER,
      langCode,
    );
  },

  getFeedbackContentKey(feedbackType) {
    const typeWithoutUnderline = feedbackType
      .toLowerCase()
      .replace(/_+./g, s => s.slice(-1).toUpperCase());
    return `client.status.${typeWithoutUnderline}`;
  },
};
