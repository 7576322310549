import classnames from 'classnames';
import styled from 'styled-components';
import { useState, useCallback, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { colors, clearfix, mediaMin } from '../../../utils/css';
import { LessonMenuItem } from './LessonMenuItem/LessonMenuItem.jsx';
import { LessonBurgerButton } from './LessonBurgerButton/LessonBurgerButton.jsx';
import { LESSON_PARTS } from '../../../constants/lessonParts';
import {
  getAlphabeticalOrderUrl,
  getExtrasUrl,
  getManuscriptUrl,
} from '../../../utils/url/urlFactory';
import { getFirstOfTypeNamedUrl, hasLessonPart } from '../../../utils/lessonUtils';
import { ModalIntegration } from '../../Modal/ModalIntegration.jsx';
import { StyledResetModal as ResetModal } from '../../Modal/StyledResetModal.jsx';
import { deleteLessonProgress } from '../../../state/progress/learnProgressDuck';
import { useTranslation } from '../../../hooks/useTranslation';
import { useStructuralLessonData } from '../../../context/StructuralLessonDataContext.jsx';
import { useOnFocusout } from '../../../hooks/useOnFocusout';
import { useExerciseManager } from '../../../hooks/useExerciseManager';
import { DetailPageHeadline } from '../../Headline/Headline.jsx';
import { keyCodes } from '../../../constants/keyCodes';
import { DarkThemeProvider } from '../../../context/ThemeContext.jsx';

const useKeyboardWrapping = (navRef, isMenuCollapsed) => {
  useEffect(() => {
    if (isMenuCollapsed) {
      return undefined;
    }

    const links = navRef.current.getElementsByTagName('a');
    const lastLink = links.item(links.length - 1);

    const burgerButton = navRef.current.querySelector(
      '.lesson-title-button-container-wrapper button',
    );

    if (!lastLink || !burgerButton) {
      console.error("Can't find expected entries in lesson menu");
      return undefined;
    }

    const goToStart = evt => {
      if (!evt.shiftKey && evt.key === keyCodes.TAB) {
        evt.preventDefault();
        burgerButton.focus();
      }
    };

    const goToEnd = evt => {
      if (evt.shiftKey && evt.key === keyCodes.TAB) {
        evt.preventDefault();
        lastLink.focus();
      }
    };

    lastLink.addEventListener('keydown', goToStart);
    burgerButton.addEventListener('keydown', goToEnd);

    return () => {
      lastLink.removeEventListener('keydown', goToStart);
      burgerButton.removeEventListener('keydown', goToEnd);
    };
  }, [navRef, isMenuCollapsed]);
};

export const LessonMenu = ({ lessonId, lessonName, lessonOverviewParts, hasExtras }) => {
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(true);
  const closeMenu = useCallback(() => setIsMenuCollapsed(true), []);

  const [isResetModalOpen, setResetModalOpen] = useState(false);
  const { structuralData } = useStructuralLessonData();
  const { contentURL, isFromPlacementCourse, isFinalTest, lessonParentType, lessonPath } =
    structuralData;

  const { firstExerciseLink, allExerciseCount } = useExerciseManager();

  // firstExerciseLink returns the result path as fallback. That is unwanted in this case.
  const firstExercisePath = allExerciseCount > 0 ? firstExerciseLink() : undefined;

  const dispatch = useDispatch();
  const history = useHistory();

  /** @type {React.MutableRefObject<HTMLElement | undefined>} */
  const navRef = useRef();
  useOnFocusout(navRef, closeMenu);
  useKeyboardWrapping(navRef, isMenuCollapsed);

  const grammarAria = useTranslation('lesson.menu.grammar_aria');
  const vocabularyAria = useTranslation('lesson.menu.vocabulary_aria');

  const onConfirmResetLessonProgress = () => {
    dispatch(deleteLessonProgress(+lessonId));
    setResetModalOpen(false);
    history.push(lessonPath);
  };

  const onCancelResetLessonProgress = () => {
    setResetModalOpen(false);
  };

  const hasExercisesReset =
    hasLessonPart(lessonOverviewParts, LESSON_PARTS.EXERCISE) &&
    !isFromPlacementCourse &&
    !isFinalTest;

  const { overviewId, overviewTranslationKey } = (() => {
    if (isFromPlacementCourse) {
      return {
        overviewId: 'placement-dashboard',
        overviewTranslationKey: 'lesson.menu.placementDashboard',
      };
    }
    if (lessonParentType === 'Course') {
      return {
        overviewId: 'course-overview',
        overviewTranslationKey: 'lesson.menu.courseOverview',
      };
    }
    return {
      overviewId: 'overview',
      overviewTranslationKey: 'lesson.menu.overview',
    };
  })();

  return (
    <StyledLessonMenu
      ref={navRef}
      tabIndex="-1"
      onKeyDown={e => {
        if (e.key === keyCodes.ESCAPE) {
          closeMenu();
        }
      }}
    >
      <DarkThemeProvider>
        <div className="lesson-nav-layout-wrapper">
          <div className="lesson-title-button-container-wrapper">
            <DetailPageHeadline>{lessonName}</DetailPageHeadline>
            <LessonBurgerButton {...{ isMenuCollapsed, setIsMenuCollapsed }} />
          </div>
          <div className="lesson-nav-menu-container-wrapper">
            <div
              id="lecture-nav-user-menu"
              className={classnames('lesson-nav-menu-wrapper', { collapsed: isMenuCollapsed })}
              aria-expanded={!isMenuCollapsed}
            >
              <ul className="lesson-nav-menu">
                <LessonMenuItem
                  onClick={() => setIsMenuCollapsed(!isMenuCollapsed)}
                  to={lessonPath}
                  translationKey="lesson.menu.description"
                  progressIconClass="half"
                />
                {firstExercisePath && hasLessonPart(lessonOverviewParts, LESSON_PARTS.EXERCISE) && (
                  <LessonMenuItem
                    onClick={() => {
                      setIsMenuCollapsed(!isMenuCollapsed);
                    }}
                    to={firstExercisePath}
                    id="exercise-list"
                    translationKey="lesson.menu.exercises"
                    progressIconClass="full"
                  />
                )}
                {hasLessonPart(lessonOverviewParts, LESSON_PARTS.GRAMMAR) && (
                  <LessonMenuItem
                    onClick={() => setIsMenuCollapsed(!isMenuCollapsed)}
                    to={getFirstOfTypeNamedUrl(lessonOverviewParts, LESSON_PARTS.GRAMMAR)}
                    id="grammar"
                    translationKey="lesson.menu.grammar"
                    progressIconClass="none"
                    ariaLabel={grammarAria}
                    rel="noopener noreferrer"
                    target="_blank"
                  />
                )}
                {hasLessonPart(lessonOverviewParts, LESSON_PARTS.PHONEMIC_CHART) && (
                  <LessonMenuItem
                    onClick={() => setIsMenuCollapsed(!isMenuCollapsed)}
                    to={getAlphabeticalOrderUrl(lessonPath)}
                    id="alphabetical-order"
                    translationKey="lesson.menu.alphabeticalOrder"
                    progressIconClass="full"
                    rel="noopener noreferrer"
                    target="_blank"
                  />
                )}
                {hasLessonPart(lessonOverviewParts, LESSON_PARTS.VOCABULARY_GLOSSARY) && (
                  <LessonMenuItem
                    onClick={() => setIsMenuCollapsed(!isMenuCollapsed)}
                    to={getFirstOfTypeNamedUrl(
                      lessonOverviewParts,
                      LESSON_PARTS.VOCABULARY_GLOSSARY,
                    )}
                    id="vocabulary"
                    translationKey="lesson.menu.vocabulary"
                    progressIconClass="full"
                    ariaLabel={vocabularyAria}
                    rel="noopener noreferrer"
                    target="_blank"
                  />
                )}
                {hasLessonPart(lessonOverviewParts, LESSON_PARTS.REGIONAL_STUDIES) && (
                  <LessonMenuItem
                    onClick={() => setIsMenuCollapsed(!isMenuCollapsed)}
                    to={getFirstOfTypeNamedUrl(lessonOverviewParts, LESSON_PARTS.REGIONAL_STUDIES)}
                    id="regional-studies"
                    translationKey="lesson.menu.regionalStudies"
                    progressIconClass="none"
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                )}
                {hasLessonPart(lessonOverviewParts, LESSON_PARTS.MANUSCRIPT) && (
                  <LessonMenuItem
                    onClick={() => setIsMenuCollapsed(!isMenuCollapsed)}
                    to={getManuscriptUrl(lessonPath)}
                    id="manuscript"
                    translationKey="lesson.menu.manuscript"
                    progressIconClass="none"
                  />
                )}
                {hasExtras && (
                  <LessonMenuItem
                    onClick={() => setIsMenuCollapsed(!isMenuCollapsed)}
                    to={getExtrasUrl(lessonPath)}
                    id="extras"
                    translationKey="lesson.menu.extras"
                    progressIconClass="none"
                  />
                )}
                {hasExercisesReset && (
                  <LessonMenuItem
                    onClick={() => {
                      setIsMenuCollapsed(!isMenuCollapsed);
                      setResetModalOpen(true);
                    }}
                    to="#"
                    id="reset"
                    translationKey="lesson.menu.reset"
                  />
                )}
                {contentURL && (
                  <LessonMenuItem
                    onClick={() => setIsMenuCollapsed(!isMenuCollapsed)}
                    to={contentURL}
                    id={overviewId}
                    translationKey={overviewTranslationKey}
                  />
                )}
              </ul>
            </div>
          </div>
        </div>
      </DarkThemeProvider>
      {hasExercisesReset && (
        <ModalIntegration isOpen={isResetModalOpen}>
          <ResetModal
            fadeIn={isResetModalOpen}
            onConfirmClicked={onConfirmResetLessonProgress}
            onCancelClicked={onCancelResetLessonProgress}
          />
        </ModalIntegration>
      )}
    </StyledLessonMenu>
  );
};

export const StyledLessonMenu = styled('nav')`
  display: grid;

  margin-bottom: 1rem;

  .lesson-nav-layout-wrapper {
    background-color: ${colors.LG_ORANGE};
    position: relative;
    top: 0;
    width: 100%;
  }

  .lesson-title-button-container-wrapper {
    display: grid;
  }

  ${DetailPageHeadline} {
    text-align: center;
    margin: 15px 0;
    padding: 3px 50px;

    ${mediaMin.md`
      padding: 11px 65px;
    `}

    ${mediaMin.xl`
        padding-top: 5px;
        padding-bottom: 5px;
    `}
  }

  .lesson-nav-menu-container-wrapper {
    position: absolute;
    width: 100%;
    z-index: 99;
  }

  .lesson-nav-menu-wrapper {
    border-top: 1px solid ${colors.LG_WHITE};
    box-shadow: 1px 10px 20px 0 ${colors.LG_TRANSPARENT_BLACK_25};
    margin: 0;
    padding: 0;
    width: 100%;
    max-height: 800;

    &.collapsed {
      max-height: 0;
      display: none;
      box-shadow: none;
    }

    ${mediaMin.xl`
      box-shadow: none;
    `}
  }

  .lesson-nav-menu {
    background-color: ${colors.LG_ORANGE};
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;

    ${clearfix}
    ${mediaMin.xl`
      /* @noflip */
      float: right;
      width: 400px;
    `}
    > li {
      margin: 0;
    }
  }
`;
