import styled from 'styled-components';
import { StyledLinkTeaser as LinkTeaser } from './LinkTeaser.jsx';
import { colors, columnSize } from '../../../utils/css';
import { ContentBlockHeadline } from '../../Headline/Headline.jsx';

export const LinkListReferenceTeaser = ({ className, contents }) => {
  const { shortTitle, externalLinks, internalLinks } = contents;
  return (
    <div className={className}>
      <ContentBlockHeadline as="h3">{shortTitle}</ContentBlockHeadline>
      <div>
        {[...internalLinks, ...externalLinks].map(link => (
          <LinkTeaser contents={link} key={link.id} />
        ))}
      </div>
    </div>
  );
};

export const StyledLinkListReferenceTeaser = styled(LinkListReferenceTeaser)`
  background-color: ${colors.LG_WHITE};
  padding: 0 ${columnSize.c2} 2rem;

  > ${ContentBlockHeadline} {
    margin: 2rem 0;
  }
  > div {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: ${columnSize.c2};
  }
`;
