import { useMemo } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { colors, mediaMin, columnSize } from '../../../utils/css';
import { contentBlockStyles } from '../cssCocoStyles';
import { StyledArticleInfo as ArticleInfo } from '../ArticleInfo.jsx';

/**
 * @param {{
 *   className?: string,
 *   contents: {
 *     id: React.Key,
 *     modelType: unknown,
 *     name: string,
 *     text: string,
 *   }[],
 * }} props
 */
export const InfoBlock = ({ className, contents, background }) => {
  const filteredContents = useMemo(() => contents.filter(isArticleInfo), [contents]);

  return (
    <div className={classNames(className, background)}>
      {filteredContents.map(info => (
        <ArticleInfo key={info.id} contents={info} />
      ))}
    </div>
  );
};

export const StyledInfoBlock = styled(InfoBlock)`
  ${contentBlockStyles}

  display: grid;
  grid-template-areas: 'eyecatcher';
  grid-template-columns: 1fr;
  padding: 0 ${columnSize.c2};

  ${mediaMin.xl`
    grid-template-areas: 'eyecatcher eyecatcher';
    grid-template-columns: 1fr 1fr;
  `}

  > ${ArticleInfo} {
    margin-bottom: 1rem;

    &:first-of-type {
      grid-area: eyecatcher;
    }
  }

  &.white {
    background-color: ${colors.LG_WHITE};
  }

  &.grey {
    background-color: ${colors.BLUE_GREY_01};
  }
`;

/**
 * @param {{ modelType: unknown }
 */
function isArticleInfo(info) {
  return info.modelType === 'ARTICLE';
}
