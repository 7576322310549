import { memoizeWith } from 'ramda';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { colors } from '../../utils/css';
import { responsiveDwPictureFragment } from '../ResponsiveDwPicture/ResponsiveDwPicture.jsx';
import { buildSrcSet, getSourcesMeta } from './utils';
import { formatLg } from '../../utils/imgUtils';

const fragmentName = 'HeroImage';
export const heroImageFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on AssociationsAspect {
          mainContentImageLink {
            additionalInformation
            target {
              ...${responsiveDwPictureFragment.name}
            }
          }
      }
      ${responsiveDwPictureFragment.fragment()}
    `,
  ),
};

export const HeroImage = ({ className, mainContentImageLink }) => {
  if (!(mainContentImageLink && mainContentImageLink?.target)) return null;

  const image = mainContentImageLink.target;
  const alt = mainContentImageLink.additionalInformation;

  const fallbackFormatGroupName = '60X';
  const fallbackFormat = formatLg[fallbackFormatGroupName];

  return (
    <picture className={className}>
      {getSourcesMeta({
        staticUrlTemplate: image.staticUrl,
        ext: 'webp',
      }).map((sourceMeta, index) => (
        <source key={index} type="image/webp" {...sourceMeta} width="100" />
      ))}
      {getSourcesMeta({ staticUrlTemplate: image.staticUrl }).map((sourceMeta, index) => (
        <source key={index} type="image/jpeg" {...sourceMeta} width="100" />
      ))}

      <img
        // eslint-disable-next-line react/no-unknown-property
        fetchpriority="high"
        rel="preload"
        src=""
        srcSet={buildSrcSet({
          staticUrlTemplate: image.staticUrl,
          formatGroupName: fallbackFormatGroupName,
        })}
        width="100"
        height={fallbackFormat.aspectRatio}
        title={alt}
        alt={alt}
      />
    </picture>
  );
};

export const StyledHeroImage = styled(HeroImage)`
  img {
    display: block;
    height: auto;
    width: 100%;
    object-fit: cover;
    background-color: ${colors.DW_GREY_02};
  }
`;
