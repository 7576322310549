import styled from 'styled-components';
import { useMemo } from 'react';
import { StyledCarousel as Carousel } from '../../Carousel/Carousel.jsx';
import { StyledLessonTeaser as LessonTeaser } from '../Teaser/LessonTeaser.jsx';
import { StyledArticleTeaser as ArticleTeaser } from '../Teaser/ArticleTeaser.jsx';
import { StyledReferenceTeaser as ReferenceTeaser } from '../Teaser/ReferenceTeaser.jsx';
import { StyledCourseTeaser as CourseTeaser } from '../Teaser/CourseTeaser.jsx';
import { mediaMax, mediaMin, columnSize, fontSize } from '../../../utils/css';
import { carouselTeaserOverrideStyles } from '../Teaser/BigImageTeaser.jsx';
import { selectLatestSeriesTeasers } from '../Teaser/seriesTeaserUtils';
import { TeaserHeadline } from '../../Headline/Headline.jsx';

export const CAROUSEL_TEASER_TYPES = Object.freeze({
  LESSON: LessonTeaser,
  ARTICLE: ArticleTeaser,
  REFERENCE: ReferenceTeaser,
  COURSE: CourseTeaser,
});

export const CarouselBlock = ({ className, contents }) => {
  const teasers = useMemo(
    () =>
      [
        ...selectLatestSeriesTeasers(contents, isAllowedCarouselTeaser),
        ...contents.filter(isAllowedCarouselTeaser),
      ].map(toTeaserComponent),
    [contents],
  );

  const isContentAvailable = teasers && teasers.length > 0;

  const firstTeaserIsRtl = teasers[0]?.content?.isRtl ?? false;

  return isContentAvailable ? (
    <div className={className}>
      <Carousel isRtl={firstTeaserIsRtl}>
        {teasers.map(({ content, Tag }) => (
          <Tag className="carousel-teaser-wrap" contents={content} key={content.id} tabIndex="-1" />
        ))}
      </Carousel>
    </div>
  ) : null;
};

export const StyledCarouselBlock = styled(CarouselBlock)`
  ${mediaMax.lg`
    width: 100vw;
  `};

  /*
   * NOTE: Teaser font-size depends on how much space the teaser takes.
   *       In carousel it always takes 2/3 or full width and has therefore plus2 size.
   */
  ${TeaserHeadline} {
    ${fontSize.plus2}
  }

  .carousel-teaser-wrap {
    padding: 1rem ${columnSize.c3} 3rem;
    width: 100%;
    ${mediaMin.xl`
      padding: 1rem ${columnSize.c2} 4rem;
    `};

    ${carouselTeaserOverrideStyles}
  }
`;

/**
 * @template {{ modelType: unknown }} T
 * @param {T} teaser
 * @returns {teaser is T & { modelType: keyof CAROUSEL_TEASER_TYPES }}
 */
export function isAllowedCarouselTeaser(teaser) {
  return Object.keys(CAROUSEL_TEASER_TYPES).includes(teaser.modelType);
}

const toTeaserComponent = content => ({ Tag: CAROUSEL_TEASER_TYPES[content.modelType], content });
