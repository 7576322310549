import classnames from 'classnames';
import styled, { css } from 'styled-components';
import { useMemo } from 'react';
import { Link } from '../../Link/Link.jsx';
import { getLearningLevels } from '../../../utils/courseUtils';
import { mediaMin, columnSize, colors } from '../../../utils/css';
import { StyledResponsiveDwPicture as ResponsiveDwPicture } from '../../ResponsiveDwPicture/ResponsiveDwPicture.jsx';
import {
  canDisplayPublicationDate,
  StyledPublicationDate as PublicationDate,
} from '../../PublicationDate/PublicationDate.jsx';
import { DateTimeInfo } from '../../Info/Info.jsx';
import { TeaserHeadline } from '../../Headline/Headline.jsx';
import { TeaserText } from '../../Text/Text.jsx';

/**
 * @param {{
 *   className?: string;
 *   image?: { targetId: number, additionalInformation: string };
 *   title?: string;
 *   teaser?: string;
 *   publicationDateConfig?: React.ComponentProps<typeof PublicationDate>;
 *   dkLearningLevel?: number | null;
 *   isRtl?: boolean;
 * } & React.ComponentProps<Link>} props
 */
export const BigImageTeaser = ({
  className,
  image,
  title,
  referenceTitle,
  teaser,
  publicationDateConfig,
  dkLearningLevel,
  isRtl,
  ...restProps
}) => {
  const { hasLearningLevel, levelString } = useMemo(() => {
    const learningLevels = getLearningLevels(dkLearningLevel);
    return {
      hasLearningLevel: learningLevels.length > 0,
      levelString: learningLevels.join(', '),
    };
  }, [dkLearningLevel]);

  const displaysPublicationDate = useMemo(
    () => canDisplayPublicationDate(publicationDateConfig),
    [publicationDateConfig],
  );

  return (
    <Link className={classnames(className, { rtl: isRtl })} title={title} {...restProps}>
      {image && <ResponsiveDwPicture alt={image.name} image={image} />}
      {referenceTitle && !title && <h3 className="sr-only">{referenceTitle}</h3>}
      {(title || teaser) && (
        <div className="teaser-title-description-wrapper">
          <TeaserHeadline>{title}</TeaserHeadline>
          <TeaserText as="div">{teaser}</TeaserText>
        </div>
      )}
      {(hasLearningLevel || displaysPublicationDate) && (
        <DateTimeInfo as="div">
          {hasLearningLevel && <span>{levelString}</span>}
          {hasLearningLevel && displaysPublicationDate && <span> | </span>}
          <PublicationDate {...publicationDateConfig} />
        </DateTimeInfo>
      )}
    </Link>
  );
};

export const StyledBigImageTeaser = styled(BigImageTeaser)`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  text-decoration: none;

  > div {
    /* @noflip */
    direction: ltr;
    /* @noflip */
    text-align: left;
  }

  &.rtl > div {
    /* @noflip */
    direction: rtl;
    /* @noflip */
    text-align: right;
  }
  ${mediaMin.lg`
    &.single {
      display: grid;
      grid-template-columns: ${columnSize.c16} ${columnSize.c8};
      grid-column-gap: ${columnSize.c1};
    }
  `}

  ${ResponsiveDwPicture} {
    ${mediaMin.md`
      object-fit: contain;
    `}
  }

  ${TeaserHeadline} {
    margin: 0 0 0.5rem;
    overflow-wrap: anywhere;
  }

  ${TeaserText} {
    overflow-wrap: anywhere;
    /** Let description grow as much as possible, to push footer to the bottom */
    flex-grow: 1;
  }

  ${DateTimeInfo} {
    > ${PublicationDate} {
      display: inline;
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;

    ${TeaserHeadline} {
      text-decoration: underline;
    }
  }
`;

export const carouselTeaserOverrideStyles = css`
  ${DateTimeInfo} {
    color: ${colors.BLUE_GREY_02};
  }

  &${StyledBigImageTeaser} {
    &:focus {
      outline: none;
    }

    .teaser-title-description-wrapper {
      flex-direction: column;
    }

    ${mediaMin.xl`
      display: grid;
      grid-template-columns: ${columnSize.c16} ${columnSize.c8};
      grid-column-gap: ${columnSize.c1};
      align-items: start;
      
      ${ResponsiveDwPicture} { 
        grid-row: 1 / 2;
      }
      
      .teaser-title-description-wrapper {
        grid-column: 2;
        grid-row: 1;
      }
      
      ${TeaserHeadline} {
        padding-top: 0px;
        margin: 0px 0px 10px 0px;
      }
                  
      ${DateTimeInfo} {
        grid-column: 2;
        grid-row: 1 / 1;
        align-self: end;
      } 
    `}
  }
`;
