import styled from 'styled-components';
import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { StyledContentNavTitle as ContentNavTitle } from '../../../ContentContainer/ContentNavTitle/ContentNavTitle.jsx';
import { StyledLessonExerciseHeadline as LessonExerciseHeadline } from '../LessonExerciseHeadline/LessonExerciseHeadline.jsx';
import { MediaInputVideo } from '../../../MediaInput/MediaInputVideo/MediaInputVideo.jsx';
import { MediaInputImage } from '../../../MediaInput/MediaInputImage/MediaInputImage.jsx';
import { MediaInputAudio } from '../../../MediaInput/MediaInputAudio/MediaInputAudio.jsx';
import { ExerciseLeavingPrompt } from './ExerciseLeavingPrompt.jsx';
import { StyledManuscript as Manuscript } from '../../../Manuscript/Manuscript.jsx';
import { findAudioWithContentLinkMatching } from '../../../../utils/audioUtils';
import { AudioProvider } from '../../../../context/AudioContext.jsx';
import { lessonExerciseAnswerFragment } from '../LessonExerciseAnswer/ExerciseAnswer.jsx';
import { lessonExerciseQuestionFragment } from '../LessonExerciseQuestion/LessonExerciseQuestion.jsx';
import { exerciseAreaFragment, ExerciseArea } from './ExerciseArea.jsx';
import { gridSpacingStyles } from '../utils/exerciseCustomCSS';

const fragmentName = 'LessonExerciseItem';
export const lessonExerciseItemFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on Inquiry {
        id
        inquiryDescription
        inquiryType
        selectionType
        sortingType
        mediaType
        learningMetaInfo {
          achievableScore
        }
        ...${lessonExerciseAnswerFragment.name}
        ...${lessonExerciseQuestionFragment.name}
        ...${exerciseAreaFragment.name}
      }

      ${lessonExerciseQuestionFragment.fragment()}
      ${lessonExerciseAnswerFragment.fragment()}
      ${exerciseAreaFragment.fragment()}      
    `,
  ),
};

export const getExerciseMediaInputComponentByType = data => {
  const { inputType, videos } = data.content;
  // description audio has mainContent === true, input audio has mainContent === false
  const inputAudio = findAudioWithContentLinkMatching(cl => !cl.mainContent, data.content);

  switch (inputType) {
    case 'NONE':
      return null;
    case 'TEXT':
      return null;
    case 'IMAGE':
      return <MediaInputImage data={data} />;
    case 'AUDIO':
      return <div>{inputAudio !== undefined && <MediaInputAudio data={data} />}</div>;
    case 'VIDEO':
      return <div>{videos.length > 0 && <MediaInputVideo data={data} />}</div>;
    default:
      return null;
  }
};

export const LessonExerciseItem = ({ className, data, exerciseCounter, manuscriptContent }) => {
  const mediaInputComponent = getExerciseMediaInputComponentByType(data);

  return (
    <AudioProvider>
      <div className={className}>
        <ExerciseLeavingPrompt />
        {mediaInputComponent && <section className="media-input">{mediaInputComponent}</section>}
        <div className="content">
          <ContentNavTitle title={exerciseCounter} />
          <LessonExerciseHeadline className="exercise-headline" data={data} />
          {manuscriptContent.manuscript && (
            <Manuscript
              knowledges={manuscriptContent.knowledges}
              manuscript={manuscriptContent.manuscript}
            />
          )}
          <ExerciseArea data={data} />
        </div>
      </div>
    </AudioProvider>
  );
};

export const StyledLessonExerciseItem = styled(LessonExerciseItem)`
  .media-input {
    margin-bottom: 1.5rem;
  }

  .content {
    ${gridSpacingStyles}
  }

  ${Manuscript} {
    margin: 2rem 0 0;
    h4 {
      margin: 0;
    }
  }

  .exercise-headline {
    margin: 0;
  }
`;
