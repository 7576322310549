import styled from 'styled-components';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { StyledContentNavTitle as ContentNavTitle } from '../../../ContentContainer/ContentNavTitle/ContentNavTitle.jsx';
import { StyledLessonExerciseStats as LessonExerciseStats } from '../LessonExerciseStats/LessonExerciseStats.jsx';
import { StyledStandardButton as StandardButton } from '../../../StandardButton/StandardButton.jsx';
import { StyledLessonExerciseItem as LessonExerciseItem } from '../LessonExerciseItem/LessonExerciseItem.jsx';
import { createLinkToResultPage } from '../../../../utils/url/urlFactory';
import { useExerciseProgress } from '../workflow/ExerciseProgress.jsx';
import {
  addLessonProgress,
  readLessonProgress,
} from '../../../../state/progress/learnProgressDuck';
import { createLearnProgressData } from '../../../../state/progress/learnProgress';
import { useExerciseManager } from '../../../../hooks/useExerciseManager';
import { isLessonInImproveModeSelector } from '../../../../state/mode/lessonExerciseModeSelectors';
import { useStructuralLessonData } from '../../../../context/StructuralLessonDataContext.jsx';
import { colors, columnSize, mediaMax } from '../../../../utils/css';
import { useLanguage } from '../../../../context/LanguageContext';

export const LessonExerciseNavigation = ({
  className,
  data,
  lessonAchievableScore,
  courseId,
  manuscriptContent,
  lessonPath,
}) => {
  const history = useHistory();
  const { lessonId, exerciseId } = useParams();
  const { langCode } = useLanguage();
  const { exerciseProgress, resetProgress } = useExerciseProgress();
  const dispatch = useDispatch();
  const lessonIdNumber = +lessonId;
  const exerciseIdNumber = +exerciseId;

  const {
    structuralData: { isFromPlacementCourse, isFinalTest },
  } = useStructuralLessonData();

  const isLessonInImproveMode = useSelector(isLessonInImproveModeSelector(lessonIdNumber));

  const {
    nextExerciseLink,
    currentExerciseCount,
    allExerciseCount,
    filterExerciseDataIfInImproveMode,
  } = useExerciseManager();

  useEffect(() => {
    // reset local progress state in case of browser back and forward buttons have been used
    const unlisten = history.listen((_, action) => {
      if (action === 'POP') {
        resetProgress();
        if (isLessonInImproveMode) {
          history.push(createLinkToResultPage({ isFromPlacementCourse, isFinalTest, lessonPath }));
        }
      }
    });
    return () => unlisten();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    dispatch(readLessonProgress(lessonIdNumber));
  }, [dispatch, lessonIdNumber]);

  const exerciseCounter = `${currentExerciseCount} / ${allExerciseCount}`;

  const handleNextButtonClick = ({ exerciseAchievableScore }) => {
    const progressMetaData = createLearnProgressData({
      langCode,
      lessonId: lessonIdNumber,
      courseId,
      lessonAchievableScore,
      exerciseId: exerciseIdNumber,
      allExerciseCount,
      exerciseProgress,
      exerciseAchievableScore,
    });
    dispatch(addLessonProgress(progressMetaData));
    resetProgress();
    const nextPath =
      nextExerciseLink ||
      createLinkToResultPage({ isFromPlacementCourse, isFinalTest, lessonPath });
    history.push(nextPath);
  };
  const {
    learningMetaInfo: { achievableScore: exerciseAchievableScore },
  } = data.content;

  const filteredData = filterExerciseDataIfInImproveMode(data);
  const amountOfInquiries = filteredData.content.inquiries.length;

  return (
    <main className={className}>
      <LessonExerciseItem {...{ data: filteredData, exerciseCounter, manuscriptContent }} />
      <div>
        <LessonExerciseStats amountOfInquiries={amountOfInquiries} />
        <StandardButton
          id="nextButton"
          titleKey="common.next"
          data-testid="nextExerciseButton"
          onClick={() =>
            handleNextButtonClick({
              exerciseAchievableScore,
            })
          }
        />
        <ContentNavTitle title={exerciseCounter} />
      </div>
    </main>
  );
};

export const StyledLessonExerciseNavigation = styled(LessonExerciseNavigation)`
  display: flex;
  flex-direction: column;

  ${mediaMax.sm`
    > ${LessonExerciseItem} {
      padding: 0 ${columnSize.c2};
    }
  `}

  > div:last-child {
    background-color: ${colors.BLUE_GREY_01};
    display: flex;
    flex-direction: column;
    padding-inline: ${columnSize.c2};

    > ${LessonExerciseStats} {
      margin: 2rem 0;
    }

    ${ContentNavTitle} {
      margin-bottom: 0.5rem;
    }

    > ${StandardButton} {
      align-self: center;
      margin-bottom: 3rem;

      /* Special case for the next button */
      --bg-color: ${colors.DW_DARK_BLUE_NEW};
      color: ${colors.LG_WHITE};
    }
  }
`;
