import classnames from 'classnames';
import gql from 'graphql-tag';
import { useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { assoc, memoizeWith } from 'ramda';
import { LessonExerciseQuestion } from '../LessonExerciseQuestion/LessonExerciseQuestion.jsx';
import { StyledLessonExerciseHint as LessonExerciseHint } from '../LessonExerciseHint/LessonExerciseHint.jsx';
import { LessonExerciseHiddenAudio } from '../LessonExerciseHiddenAudio/LessonExerciseHiddenAudio.jsx';
import { StyledSolveCheckButton as SolveCheckButton } from '../../../SolveCheckButton/SolveCheckButton.jsx';
import { StyledSortingAnswerButton as SortingAnswerButton } from './SortingAnswerButton.jsx';
import { findFeedbackAudio } from '../../../../utils/audioUtils';
import { shuffle, sortObjectsByProp } from '../../../../utils/commons';
import { useInquiryHandling } from '../workflow/useInquiryHandling';
import { useAudio } from '../../../../hooks/useAudio';
import { INQUIRY_STATE } from '../workflow/inquiryState';
import { isNormalizedTextEqual } from '../utils/exerciseUtils';
import { baseExerciseStyles, joinQuestionStyles } from '../utils/exerciseCustomCSS';
import { colors, mediaMax } from '../../../../utils/css';

const fragmentName = 'SortingExercise';
export const sortingExerciseFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    () => '',
    () => gql`
      fragment ${fragmentName} on Inquiry {
        alternatives {
          id   
          order
          alternativeText
          images {
            id
            staticUrl
          }
        }
      }       
    `,
  ),
};

export const SortingExercise = ({ className, inquiry }) => {
  const {
    alternatives,
    audios,
    id: inquiryId,
    inquiryDescription,
    mainContentImage,
    learningMetaInfo: { achievableScore },
    sortingType,
  } = inquiry;
  const answerType = inquiry.mediaType.toLowerCase();

  const isSolutionAudioFallbackMode = Boolean(mainContentImage);
  const feedbackAudio = findFeedbackAudio(isSolutionAudioFallbackMode, audios);
  const { target: answerAudio } = feedbackAudio || {};
  const { mp3Src: feedbackAudioUrl } = answerAudio || {};

  const feedbackAudioRef = useRef();
  const { playWrongDefaultAudioFeedBack, playCorrectDefaultAudioFeedBack, addRef, playAudio } =
    useAudio();

  const {
    isSolveButtonDisabled,
    isCheckButtonDisabled,
    handleCorrectAnswer,
    handleWrongAnswer,
    handleSolved,
    inquiryState,
    isDisabled,
  } = useInquiryHandling({ inquiryId, achievableScore });

  const [choosenAnswers, setChoosenAnswers] = useState([]);
  const [selectableAnswers, setSelectableAnswers] = useState(alternatives);

  useEffect(() => {
    setSelectableAnswers(answers =>
      shuffle(
        answers.map(alternative => ({
          ...alternative,
          isSelected: false,
        })),
      ),
    );

    if (feedbackAudioUrl) {
      return addRef(feedbackAudioRef);
    }

    return undefined;
  }, [feedbackAudioUrl, addRef]);

  const isCorrectOrder = (value, index) => value.order === index + 1;

  const isDuplicatedValue = (value, index) =>
    isNormalizedTextEqual(value.alternativeText, alternatives[index].alternativeText);

  const isCorrect =
    choosenAnswers.length === alternatives.filter(alternative => alternative.order > 0).length &&
    choosenAnswers.every(
      (value, index) => isCorrectOrder(value, index) || isDuplicatedValue(value, index),
    );

  const playCorrectAudio = event =>
    feedbackAudioUrl ? playAudio(event, feedbackAudioRef) : playCorrectDefaultAudioFeedBack();

  const onHandleSolveButtonClicked = event => {
    setChoosenAnswers(sortObjectsByProp(alternatives, 'order').map(assoc('isSelected', true)));
    setSelectableAnswers([]);
    playCorrectAudio(event);
    handleSolved();
  };

  const onHandleCheckButtonClicked = event => {
    if (isCorrect) {
      handleCorrectAnswer();
      playCorrectAudio(event);
    } else {
      handleWrongAnswer();
      playWrongDefaultAudioFeedBack();
    }
  };

  const onAnswerClicked = selectedAnswer => {
    const updateSelection = {
      ...selectedAnswer,
      isSelected: !selectedAnswer.isSelected,
    };

    if (selectedAnswer.isSelected) {
      setSelectableAnswers(currentSelectableAnswers => [
        updateSelection,
        ...currentSelectableAnswers,
      ]);
      setChoosenAnswers(choosenAnswers.filter(answer => answer.id !== updateSelection.id));
    } else {
      setChoosenAnswers(currentChoosenAnswers => [...currentChoosenAnswers, updateSelection]);
      setSelectableAnswers(selectableAnswers.filter(answer => answer.id !== updateSelection.id));
    }
  };

  return (
    <div className={className} data-exercise-id={inquiryId}>
      <div>
        <div>
          <LessonExerciseHiddenAudio ref={feedbackAudioRef} src={feedbackAudioUrl} />
          <LessonExerciseQuestion {...{ inquiry }} />
        </div>
        <div>
          <div
            className={classnames('answer-selection-area', {
              'correct-outline': inquiryState === INQUIRY_STATE.CORRECT,
              'wrong-outline': inquiryState === INQUIRY_STATE.WRONG,
              merged: sortingType === 'MERGE' && inquiryState === INQUIRY_STATE.CORRECT,
            })}
          >
            {choosenAnswers.map(answer => (
              <SortingAnswerButton
                key={answer.id}
                type={answerType}
                answer={answer}
                onClick={onAnswerClicked}
                disabled={isDisabled}
              />
            ))}
          </div>
          <div className="selectable-answers" aria-multiselectable="false">
            {selectableAnswers.map(answer => (
              <SortingAnswerButton
                key={answer.id}
                type={answerType}
                answer={answer}
                onClick={onAnswerClicked}
                disabled={isDisabled}
              />
            ))}
          </div>
        </div>
      </div>
      <LessonExerciseHint description={inquiryDescription} />
      <SolveCheckButton
        {...{
          handleCheckClicked: onHandleCheckButtonClicked,
          handleSolveClicked: onHandleSolveButtonClicked,
          isCheckButtonDisabled,
          isSolveButtonDisabled,
        }}
      />
    </div>
  );
};

const commonBorderStyles = css`
  color: ${colors.LG_WHITE};
  transition: all 0.3s linear;
  border-style: solid;
`;

export const StyledSortingExercise = styled(SortingExercise)`
  ${baseExerciseStyles}
  ${joinQuestionStyles}

  .answer-selection-area {
    position: relative;
    background: ${colors.LG_WHITE};
    border: 2px dotted #d1c9c2;
    min-height: 110px;
    overflow: hidden;
    padding: 14px 0 0 0;
    /* @noflip */
    text-align: left;
  }

  .correct-outline {
    border-color: ${colors.ACCENT_GREEN};
    ${commonBorderStyles}
  }

  .wrong-outline {
    border-color: ${colors.ACCENT_RED};
    ${commonBorderStyles}
  }

  .selectable-answers:not(:empty) {
    /* @noflip */
    text-align: right;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: flex-end;
    ${SortingAnswerButton} {
      margin: 0 0.25rem;
    }
  }
  .selectable-answers:not(:empty) {
    margin-top: 1rem;
  }

  .merged > * {
    border-radius: 0px;
    &:first-child {
      border-radius: 12px 0px 0px 12px;
    }
    &:last-child {
      border-radius: 0px 12px 12px 0px;
    }
    &:not(:first-child) {
      margin-left: 0;
      padding-left: 0.02em;
    }
    &:not(:last-child) {
      margin-right: 0;
      padding-right: 0;
    }
  }

  ${SortingAnswerButton} {
    margin: 0.1rem 0.25rem;

    &.img-answer {
      margin-bottom: 1rem;
    }

    ${mediaMax.xs`
      /* Need to subtract margins to avoid overflow */
      width: calc(100% - 0.2rem);
    `}
  }
`;
